@mixin flush($container-padding, $vertical: false) {
  max-width:     unset;
  width:         calc(100% + #{$container-padding * 2});
  margin-inline: ($container-padding * -1);

  @if ($vertical == true) {
    &:first-child {
      margin-block-start: ($container-padding * -1);
    }

    &:last-child {
      margin-block-end: ($container-padding * -1);
    }
  }
}
