@use '@angular/material' as mat;
@import '../settings/index';
@import '../tools/index';


.c-mark {
  border-bottom-style: dashed;
  border-bottom-width: 1px;
  background-color:    transparent;
  color:               currentColor;

  transition:          border ($global-transition * 0.5) $global-easing,
                       background ($global-transition * 0.5) $global-easing;

  &--image {
    position:       absolute;
    pointer-events: none;

    &.active {
      border-width: 2px;
      border-style: solid;
      z-index:      1999;
    }
  }

  &--image &__flag {
    @include z-depth(1);

    position:            absolute;
    right:               0;
    bottom:              0;
    width:               ($global-baseline * 5);
    height:              ($global-baseline * 5);
    padding:             2px;
    border-radius:       50%;
    background-position: 5px 5px;
    opacity:             1;
    pointer-events:      all;
    transform:           translateX(calc(100% + #{$global-spacing-unit-tiny}));

    transition:          opacity $global-transition $global-easing,
                         transform $global-transition $global-easing,
                         right $global-transition $global-easing,
                         box-shadow $global-transition $global-easing;

    .c-proofread-image--active & {
      opacity: 0;
    }

    .c-proofread-image--drawing & {
      opacity:        0;
      pointer-events: none;
    }

    @media print {
      background-color: transparent;
      box-shadow:       none;
      width:            ($global-baseline * 6);
      height:           ($global-baseline * 6);

      &:after {
        @include font-size($global-delta-size);

        text-shadow: 0 0 3px $red-light-primary-text;
      }

      .c-proofread-image--active & { opacity: 1; }
    }

    &:hover {
      background-color: $red-light-primary-bg;
    }
  }

  &--image.active &__flag {
    @include z-depth(3);

    visibility: hidden;
    transform:  translateX(calc(100% + #{$global-spacing-unit-small}));
  }

  &--editable {
    border:           2px dashed mat.get-color-from-palette($red-accent, darker);
    background-color: mat.get-color-from-palette($red-accent, lighter, .11);

    &:hover {
      z-index: 1999;
      cursor:  grab;

      > .c-resize-anchor { opacity: 1; }
    }

    &:active { cursor: grabbing; }
  }

  @each $type, $color in $red-marks {

    &--#{$type} {
      border-color: mat.get-color-from-palette($color, default, .22);

      &.hover {
        color:            rgba(black, .87);
        background-color: mat.get-color-from-palette($color, default, .22);
        border-color:     mat.get-color-from-palette($color, darker, 1);
      }

      &.active {
        color:            mat.get-color-from-palette($color, darker-contrast);
        background-color: mat.get-color-from-palette($color, darker, .87);
      }
    }

    &--#{$type}#{&}--image {
      border-color: transparent;

      &.active {
        background-color: mat.get-color-from-palette($color, default, .11);
        border-color:     mat.get-color-from-palette($color, darker, .87);
      }
    }
  }


  &__flag {
    @include svg-background('flag', $red-dark-disabled-button);

    color:               mat.get-color-from-palette($red-danger, default);
    display:             inline-flex;
    justify-content:     flex-end;
    align-items:         flex-end;
    vertical-align:      text-bottom;
    width:               ($global-baseline * 5);
    height:              $global-line-height;
    background-color:    $red-light-primary-bg;
    background-size:     ($global-baseline * 2.5);
    background-repeat:   no-repeat;
    background-position: 2px 2px;
    cursor:              pointer;
    transform:           translateY(1px);

    @media print {
      height: $global-gamma-size;
    }

    &::after {
      @include font-size($global-zeta-size, $line-height: inherit);

      content:     attr(data-flag-icon-index);
      font-style:  normal;
      font-weight: $bold;
      white-space: nowrap;
      position:    relative;
      right:       $global-baseline;
      bottom:      -2px;

      @media print {
        @include font-size($global-baseline + 2, $line-height: inherit);
        right: $global-baseline * 0.5;
      }
    }

    &--tentative { opacity: .5; }

    @each $type, $hue in $red-marks {

      &--#{$type} {
        @include svg-background('flag', mat.get-color-from-palette($hue, default));

        color: mat.get-color-from-palette($hue, default);

        &:hover { background-color: mat.get-color-from-palette($hue, default, .22); }
      }
    }

    &--annotation {
      @include svg-background('check', mat.get-color-from-palette($red-gray, 50));

      color:             #fff;
      background-color:  mat.get-color-from-palette($red-accent, default);
      margin-inline-end: 6px;
      border-radius:     4px;

      &:hover {
        background-color: mat.get-color-from-palette($red-accent, darker) !important;
      }

      &::after {
        content: attr(data-annotation-icon-index) !important;
      }
    }

    @media print {
      &--grupo-outros {
        @include svg-background('flag', mat.get-color-from-palette($red-gray, 900));
        color: mat.get-color-from-palette($red-gray, 900);
      }
    }

    &-info {
      @include font-size($global-delta-size);

      position:      absolute;
      width:         100%;
      max-width:     ($global-baseline * 60);
      min-height:    ($global-baseline * 12);
      border-radius: $global-radius;
      opacity:       0;
      margin-top:    0;
      z-index:       9;
      padding:       $global-spacing-unit-small ($global-baseline * 5) $global-spacing-unit-small $global-spacing-unit-small;

      transition:    opacity $global-transition $global-easing,
                     margin-top $global-transition $global-easing;

      > p {
        position:      relative;
        max-height:    ($global-baseline * 40);
        overflow-x:    hidden;
        overflow-y:    auto;
        padding-right: ($global-baseline * 5);
        margin-top:    $global-spacing-unit-small;
        z-index:       2;
      }

      &--show {
        opacity:    1;
        margin-top: $global-spacing-unit;
      }

      &--editing {
        > p { display: none; }
      }

      &-textarea {
        @include font-size($global-delta-size);

        position:         relative;
        width:            100%;
        height:           ($global-baseline * 18);
        border:           none;
        margin:           0;
        padding:          $global-spacing-unit-tiny ($global-baseline * 10) $global-spacing-unit-tiny $global-spacing-unit-small;
        background-color: transparent;
        color:            currentColor;
        z-index:          2;

        &:focus {
          appearance:   none;
          box-shadow:   none;
          border-color: currentColor;
          outline:      0;
        }
      }

      &-arrow {
        position:       absolute;
        top:            0;
        left:           50%;
        height:         0;
        width:          0;
        border-style:   solid;
        border-width:   $global-spacing-unit-small;
        pointer-events: none;
        transform:      translate(-50%, -100%);
      }

      &__auto-icon {
        @include svg-background('auto', $red-light-primary-text);

        position:            absolute;
        bottom:              0;
        right:               0;
        display:             inline-flex;
        justify-content:     center;
        align-items:         center;
        width:               ($global-baseline * 5);
        height:              ($global-baseline * 5);
        outline:             none;
        background-repeat:   no-repeat;
        background-position: center center;
        background-size:     $global-delta-size;
        z-index:             1;
      }

      &-button {
        position:            absolute;
        top:                 0;
        display:             inline-flex;
        justify-content:     center;
        align-items:         center;
        width:               ($global-baseline * 5);
        height:              ($global-baseline * 5);
        outline:             none;
        border-style:        solid;
        border-width:        ($global-baseline * 0.5);
        border-radius:       100%;
        background-repeat:   no-repeat;
        background-position: center center;
        background-size:     $global-delta-size;
        cursor:              pointer;
        z-index:             1;
        transform:           translateY(-25%);

        &--edit {
          @include svg-background('edit', mat.get-color-from-palette($red-danger, darker-contrast));

          right: ($global-baseline * 11);
        }

        &--delete {
          @include svg-background('trash', mat.get-color-from-palette($red-danger, darker-contrast));

          right: ($global-baseline * 5);
        }

        &--save,
        &--confirm {
          @include svg-background('check', mat.get-color-from-palette($red-danger, darker-contrast));

          right: ($global-baseline * 5);
        }

        &--close {
          @include svg-background('close', mat.get-color-from-palette($red-danger, darker));

          color:            mat.get-color-from-palette($red-danger, darker);
          background-color: $red-light-primary-bg;
          background-size:  $global-epsilon-size;
          right:            $global-spacing-unit-tiny * -1;
        }

      }

      @each $type, $hue in $red-marks {
        &--#{ $type } {
          color:            mat.get-color-from-palette($hue, darker-contrast);
          background-color: mat.get-color-from-palette($hue, darker);
        }

        &--#{ $type } &-arrow {
          border-color:        transparent;
          border-bottom-color: mat.get-color-from-palette($hue, darker);
        }

        &--#{ $type } &-button {
          background-color: mat.get-color-from-palette($hue, darker);
          border-color:     mat.get-color-from-palette($hue, darker);
          color:            mat.get-color-from-palette($hue, darker-contrast);

          &--delete { @include svg-background('trash', mat.get-color-from-palette($hue, darker-contrast)); }
          &--save   { @include svg-background('check', mat.get-color-from-palette($hue, darker-contrast)); }

          &--close {
            background-color: $red-light-primary-bg;
            border-color:     mat.get-color-from-palette($hue, darker);
            color:            mat.get-color-from-palette($hue, darker);
          }
        }
      }

    }
  }

}
