@import '../settings/settings.colors';

//__<<ngThemingMigrationEscapedComment0>>__

/**
 * A mixin which helps you to add depth to elements according to the
 * Google Material Design spec:
 * http://www.google.com/design/spec/layout/layout-principles.html#layout-principles-dimensionality
 *
 * Please note that the values given in the specification cannot be used as is.
 * To create the same visual experience the blur parameter has to be doubled.
 *
 * Based on: https://medium.com/@Florian/freebie-google-material-design-shadow-helper-2a0501295a2d
 *           by Florian Kutschera (@gefangenimnetz),
 *           Conceptboard GmbH (@conceptboardapp)
 *
 */


@mixin z-depth($level: 1, $important: false) {

  // important!
  @if ($important == true) {
    $important: '!important';
  } @else if ($important == false) {
    $important: null;
  } @else {
    @error "`#{$important}` needs to be `true` or `false`.";
  }


  // Simple validation
  @if not unitless($level) {

    @error "D’oh! `#{$level}` should be unitless.";

  } @else if $level == 1 {

    box-shadow:
      0 2px 2px rgba($red-dark-primary-text, .05),
      0 4px 4px rgba($red-dark-primary-text, .05) #{$important};

  } @else if $level == 2 {

    box-shadow:
      0 2px 2px rgba($red-dark-primary-text, .05),
      0 4px 4px rgba($red-dark-primary-text, .05),
      0 8px 8px rgba($red-dark-primary-text, .05) #{$important};

  } @else if $level == 3 {

    box-shadow:
      0 2px 2px rgba($red-dark-primary-text, .05),
      0 4px 4px rgba($red-dark-primary-text, .05),
      0 8px 8px rgba($red-dark-primary-text, .05),
      0 16px 16px rgba($red-dark-primary-text, .05) #{$important};

  } @else if $level == 4 {

    box-shadow:
      0 2px 2px rgba($red-dark-primary-text, .05),
      0 4px 4px rgba($red-dark-primary-text, .05),
      0 8px 8px rgba($red-dark-primary-text, .05),
      0 16px 16px rgba($red-dark-primary-text, .05),
      0 32px 32px rgba($red-dark-primary-text, .05) #{$important};

  } @else if $level == 5 {

    box-shadow:
      0 2px 2px rgba($red-dark-primary-text, .05),
      0 4px 4px rgba($red-dark-primary-text, .05),
      0 8px 8px rgba($red-dark-primary-text, .05),
      0 16px 16px rgba($red-dark-primary-text, .05),
      0 32px 32px rgba($red-dark-primary-text, .05),
      0 64px 64px rgba($red-dark-primary-text, .05) #{$important};

  } @else {

    @error "D’oh! `#{$level}` should and integer between 1 and 5.";

  }

}
