@import '../settings/index';


/* ==========================================================================
   #TABLE
   ========================================================================== */

/**
 * A simple object for manipulating the structure of HTML `table`s.
 */

.o-table {
  width: 100%;

  td, th {
    padding-left:   $global-spacing-unit-tiny;
    padding-right:  $global-spacing-unit-tiny;
    padding-top:    ($global-spacing-unit-tiny * 0.5);
    padding-bottom: ($global-spacing-unit-tiny * 0.5);
  }

  &--zebra {
    tr:nth-child(even) {
      background-color: $red-light-secondary-bg;
    }
  }

  &--horizontal {
    th {
      text-align:     left;
      white-space:    nowrap;
      padding-right:  $global-spacing-unit-small;
    }
  }

}
