@use '@angular/material' as mat;
@import 'settings/index';
@import 'tools/index';


.c-home-section {

  &__main-title,
  &__intro {
    display:         flex;
    flex-direction:  column;
    justify-content: flex-end;
    z-index:         1;
  }

  &__main-title {
    @include font-size($global-omega-size - 12, $modifier: -1);

    grid-row:    1;
    grid-column: 2 / 4;
    display:     flex;
    font-family: unquote($global-body-font);
    color:       $red-dark-primary-text;
    z-index:     1;

    @include mq($from: tablet-landscape) {
      align-items:   flex-end;
      margin-bottom: $global-spacing-unit-large;
      text-align:    end;
    }

    @include mq($from: desktop) {
      @include font-size($global-omega-size, $modifier: -1);

      grid-column:   2;
      margin-bottom: $global-spacing-unit-huge;
    }
  }

  &__intro {
    @include font-size($global-delta-size);

    grid-column:   2 / 4;
    grid-row:      2;
    padding:       0 $global-spacing-unit;
    margin-bottom: $global-spacing-unit-large;
    border-left:   1px solid;

    @include mq($from: tablet-landscape) {
      grid-column: 4 / 7;
      grid-row:    1;
    }

    @include mq($from: desktop) {
      grid-column:   3 / 5;
      margin-bottom: $global-spacing-unit-huge;
      margin-left:   0;
    }
  }

  &__video {
    grid-column:     3;
    grid-row:        3;
    display:         flex;
    justify-content: flex-start;
    align-items:     center;
    margin-left:     $global-spacing-unit;
    margin-bottom:   $global-spacing-unit-small;

    @include mq($from: tablet-landscape) {
      grid-row:      1;
      grid-column:   7;
      margin-bottom: $global-spacing-unit-large;
    }

    @include mq($from: desktop) {
      grid-column:   5;
      margin-left:   0;
      margin-bottom: $global-spacing-unit-huge;
    }

    &-icon {
      border-radius: 50%;
      cursor:        pointer;
      transition:    color $global-transition $global-easing;

      &:hover {
        color: mat.get-color-from-palette($red-purple, default);
      }
    }
  }
}
