@use '@angular/material' as mat;
@import 'settings/index';
@import 'tools/index';


.c-marketing-dashboard {

  @include z-depth(3);

  grid-template-columns: minmax(0, 100%);
  border-radius:         $global-radius;
  color:                 mat.get-color-from-palette($red-primary, darker-contrast);
  background-size:       400% 400%;
  background-position:   100% 200%;
  background-image:      linear-gradient(135deg,
    mat.get-color-from-palette($red-primary, darker) 10%,
    mat.get-color-from-palette($red-purple, default) 50%,
    mat.get-color-from-palette($red-accent, default) 75%,
    mat.get-color-from-palette($red-blue, darker) 100%
  );

  .u-title-qualifier {
    color: mat.get-color-from-palette($red-accent, lighter);
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: $red-light-primary-bg;
    border-radius:    5px;
  }

  h3 { color: inherit; }

  &__header {
    position:         static;
    padding:          $global-spacing-unit $global-spacing-unit $global-spacing-unit;
    margin-block-end: 0;
    background-color: transparent;
    border-block-end: 1px solid rgba($red-light-secondary-bg, .5);
    align-items:      flex-end;
    justify-content:  space-between;
  }

  &__title {
    @include font-size($global-omega-size + $global-beta-size);

    color: mat.get-color-from-palette($red-primary, darker-contrast);;
  }

  &__division {
    display:               grid;
    grid-gap:              $global-spacing-unit-large;
    grid-template-columns: repeat(6, 1fr);
    padding:               $global-spacing-unit-huge $global-spacing-unit-large;
    border-block-end:      1px solid rgba($red-light-secondary-bg, .5);

    &:first-child { padding-block-start: 0; }

    &:last-child { border: none; }

    &--highlight {
      @include z-depth(3);

      border-radius:       $global-radius;
      padding:             $global-spacing-unit-large;
      background-size:     100% 100%;
      background-image:    linear-gradient(135deg,
        rgba($red-light-primary-bg, .15) 10%,
        rgba($red-light-secondary-bg, .7) 90%,
      );

      color: mat.get-color-from-palette($red-primary, darker);
    }
  }

  &-card {
    @include z-depth(3);

    position:        relative;
    width:           100%;
    height:          100%;
    display:         flex;
    flex-direction:  column;
    align-items:     center;
    justify-content: center;
    text-align:      center;
    padding:         $global-spacing-unit-small;
    border-radius:   $global-radius;
    color:           mat.get-color-from-palette($red-primary, darker);

    background-color: transparent;
    background-size:  100% 100%;
    background-image: linear-gradient(135deg,
      rgba($red-light-primary-bg, .15) 10%,
      rgba($red-light-secondary-bg, .7) 90%,
    );

    > small, > p, > h4 { z-index: 1; }
  }
}
