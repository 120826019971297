@use '@angular/material' as mat;

@import '../settings/index';
@import '../tools/index';


.c-queue {
  display:          flex;
  justify-content:  flex-start;
  flex-wrap:        nowrap;
  overflow-x:       auto;
  overflow-y:       hidden;
  border-radius:    $global-radius;
  min-height:       ($global-baseline * 35);
  padding:          $global-spacing-unit $global-spacing-unit 0;
  background-color: $red-light-secondary-bg;
  background-image: linear-gradient(
                        135deg,
                        $red-light-secondary-bg 0%,
                        darken($red-light-secondary-bg, 5%) 100%
                    );

  &::-webkit-scrollbar {
    width:  $global-baseline;
    height: $global-baseline;
  }

  &::-webkit-scrollbar-track {
    background-color: $red-light-secondary-bg;
  }

  &::-webkit-scrollbar-thumb {
    background-color: mat.get-color-from-palette($red-accent, default);
  }

  &-card {
    @include z-depth(2);

    padding:       $global-spacing-unit-small $global-spacing-unit;
    margin-bottom: $global-spacing-unit;
    margin-right:  $global-spacing-unit;
    border-radius: $global-baseline;
    cursor:        pointer;

    h3 {
      @include font-size($global-gamma-size);

      min-width:     ($global-baseline * 25);
      margin-bottom: 0;
      white-space:   nowrap;
      color:         inherit;
    }

    &--package {
      color:            mat.get-color-from-palette($red-primary, default-contrast);
      background-color: mat.get-color-from-palette($red-primary, default);
      background-image: linear-gradient(135deg,
        mat.get-color-from-palette($red-primary, default) 0%,
        mat.get-color-from-palette($red-primary, lighter) 250%
                        );
    }

    &--pending {
      color:            mat.get-color-from-palette($red-primary, 900);
      background-color: mat.get-color-from-palette($red-warn, 300);
      background-image: none;
    }

    &--pending &__flag {
      background-color: mat.get-color-from-palette($red-warn, 900);
      color:            $red-light-primary-text;
      background-image: none;
    }

    &--package &__flag {
      color:            mat.get-color-from-palette($red-primary, default);
      background-color: mat.get-color-from-palette($red-primary, default-contrast);
      background-image: none;
    }

    @each $type, $hue in $red-categories {
      &--#{$type} {
        color:            mat.get-color-from-palette($hue, default);
        background-color: mat.get-color-from-palette($hue, default-contrast);
      }

      &--#{$type} &__flag {
        color:            mat.get-color-from-palette($hue, default-contrast);
        background-color: mat.get-color-from-palette($hue, default);
        background-image: linear-gradient(135deg,
          mat.get-color-from-palette($hue, default) 0%,
          mat.get-color-from-palette($hue, lighter) 250%);
      }

      &--package.c-queue-card--#{$type} {
        color:            mat.get-color-from-palette($hue, default-contrast);
        background-color: mat.get-color-from-palette($hue, default);
        background-image: linear-gradient(135deg,
          mat.get-color-from-palette($hue, default) 0%,
          mat.get-color-from-palette($hue, lighter) 250%
                          );
      }

      &--package.c-queue-card--#{$type} &__flag {
        color:            mat.get-color-from-palette($hue, default);
        background-color: mat.get-color-from-palette($hue, default-contrast);
        background-image: none;
      }
    }

    &:last-child { margin-right: 0; }

    &__flag {
      @include z-depth(2);
      @include font-size($global-epsilon-size);

      padding:       ($global-baseline * 0.5) $global-spacing-unit;
      margin-bottom: $global-spacing-unit-small;
      border-radius: 0 $global-baseline $global-baseline 0;
      font-weight:   $bold;
      transform:     translateX($global-spacing-unit * -1);
    }

    p {
      @include font-size($global-epsilon-size);

      white-space: nowrap;
    }
  }
}
