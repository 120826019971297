@import '../settings/index';
@import '../tools/index';


.g-from-server {
  @include font-size($global-delta-size);

  @include mq($from: stable) {
    column-count: 2;
    column-gap:  $global-spacing-unit;
  }

  h3 {
    @include font-size($global-epsilon-size);

    text-transform: uppercase;
    margin-bottom:  $global-spacing-unit-small;
  }

  h4 {
    @include font-size($global-epsilon-size);

    font-weight:   $regular;
    font-style:    italic;
    margin-bottom: $global-spacing-unit-tiny;
  }

  p { margin-bottom: $global-spacing-unit; }

  b { font-weight: $bold; }
}
