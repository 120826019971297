@use '@angular/material' as mat;
@import 'settings/index';
@import 'tools/index';


.c-hub-grid {
  display:               grid;
  grid-row-gap:          $global-spacing-unit;
  grid-column-gap:       0;
  grid-template-columns: $global-spacing-unit-small
                         repeat(2, 1fr)
                         $global-spacing-unit-small;
  padding:               ($global-baseline * 15) 0 0;

  background-color: $red-light-primary-bg;
  background-image: linear-gradient(135deg,
    $red-light-primary-bg 0%,
    darken($red-light-primary-bg, 5%) 100%
  );

  @include mq($from: tablet-portrait) {
    grid-template-columns: $global-spacing-unit
                           repeat(2, 1fr)
                           $global-spacing-unit;
  }

  @include mq($from: tablet-landscape) {
    grid-template-columns: $global-spacing-unit
                           repeat(6, 1fr)
                           $global-spacing-unit;
  }

  @include mq($from: desktop) {
    grid-row-gap:          0;
    grid-column-gap:       $global-spacing-unit;
    grid-template-rows:    repeat(7, max-content) $global-spacing-unit-huge;
    grid-template-columns: $global-spacing-unit-large
                           repeat(4, 1fr)
                           $global-spacing-unit-large;
  }

  @include mq($from: stable) {
    grid-column-gap:       $global-spacing-unit-large;
    grid-template-columns: minmax($global-spacing-unit-large, 1fr)
                           repeat(4, 1fr)
                           minmax($global-spacing-unit-large, 1fr);
  }

  &-item {

    &--first {
      grid-row:    1 / 5;
      grid-column: 2;
      padding-top: $global-spacing-unit-large;
    }

    &--first + &__content             { grid-row: 4; }
    &--second, &--second + &__content { grid-row: 5; }
    &--third,  &--third + &__content  { grid-row: 6; }
    &--fourth, &--fourth + &__content { grid-row: 7; }
    &--fifth,  &--fifth + &__content  { grid-row: 8; }
    &--sixth,  &--sixth + &__content  { grid-row: 9; }

    &--third, &--fifth,
    &--second + &__content,
    &--fourth + &__content,
    &--sixth + &__content {
      grid-column: 2;
    }

    &--second, &--fourth, &--sixth,
    &--first + &__content,
    &--third + &__content,
    &--fifth + &__content {
      grid-column: 3;
    }

    &--first + &__content,
    &--third + &__content,
    &--fifth + &__content {
      padding-left: $global-spacing-unit-small;
    }

    &--second + &__content,
    &--fourth + &__content,
    &--sixth + &__content {
      padding-right: $global-spacing-unit-small;
    }

    &__content {
      padding-top:      $global-spacing-unit-small;
      padding-bottom:   $global-spacing-unit-small;
      border-top-width: $global-spacing-unit-tiny;
      border-top-style: solid;
    }

    @include mq($from: tablet-landscape) {

      &--first { grid-row: 1 / 3; }

      &--first + &__content,
      &--second, &--second + &__content {
        grid-row: 2;
      }

      &--third, &--third + &__content,
      &--fourth, &--fourth + &__content {
        grid-row: 3;
      }

      &--fifth, &--fifth + &__content,
      &--sixth, &--sixth + &__content {
        grid-row: 4;
      }

      &--first, &--fifth  { grid-column: 2; }
      &--second, &--sixth { grid-column: 5; }
      &--third            { grid-column: 4; }
      &--fourth           { grid-column: 7; }

      &--first + &__content,
      &--fifth + &__content {
        grid-column: 3 / 5;
      }

      &--third + &__content {
        grid-column: 2 / 4;
      }

      &--fourth + &__content {
        grid-column: 5 / 7;
      }

      &--second + &__content,
      &--sixth + &__content {
        grid-column: 6 / 8;
      }

      &--second,
      &--fourth + &__content,
      &--sixth {
        margin-left: $global-spacing-unit;
      }

      &--first + &__content,
      &--second + &__content,
      &--fifth + &__content,
      &--sixth + &__content {
        padding-right: 0;
        padding-left:  $global-spacing-unit-small;
      }

      &--third + &__content,
      &--fourth + &__content {
        padding-right: $global-spacing-unit-small;
        padding-left:  0;
      }

    }

    @include mq($from: desktop) {

      &--second,
      &--fourth + &__content,
      &--sixth {
        margin-left: 0;
      }

      &--second + &__content,
      &--third,
      &--fourth + &__content {
        grid-row: 2;
      }

      &--first + &__content,
      &--second,
      &--third + &__content {
        grid-row: 3;
      }

      &--fifth,
      &--sixth + &__content {
        grid-row:   4;
        margin-top: $global-spacing-unit-large;
      }

      &--sixth,
      &--fifth + &__content {
        grid-row: 5;
      }

      &--first {
        grid-row:    1 / 3;
        grid-column: 1 / 3;
        padding-top: $global-baseline * 15;
      }

      &--fourth {
        grid-row:    3 / 7;
        grid-column: 5 / 7;
      }

      &--first + &__content { grid-column: 2; }
      &--fifth              { grid-column: 2; }
      &--fifth + &__content { grid-column: 2; }

      &--second              { grid-column: 3; }
      &--second + &__content { grid-column: 3; }
      &--sixth               { grid-column: 3; }
      &--sixth + &__content  { grid-column: 3; }

      &--third              { grid-column: 4; }
      &--third + &__content { grid-column: 4; }

      &--fourth + &__content { grid-column: 5; }

      &--first + &__content,
      &--third + &__content,
      &--fifth + &__content {
        justify-content: flex-end;
        padding-top:     $global-spacing-unit;
        padding-bottom:  0;
        padding-left:    $global-spacing-unit-small;
      }

      &--second + &__content,
      &--fourth + &__content,
      &--sixth + &__content {
        justify-content: flex-start;
        padding-top:     0;
        padding-bottom:  $global-spacing-unit;
        padding-left:    $global-spacing-unit-small;
      }

      &--first &__title {
        justify-content: flex-end;
        align-items:     flex-end;
        text-align:      end;
      }

      &__content { border-top: none; }
    }


    &__title {
      @include font-size($global-delta-size);

      position:         relative;
      display:          flex;
      flex-direction:   column;
      justify-content:  flex-end;
      align-items:      flex-start;
      height:           100%;
      border-radius:    $global-radius $global-radius $global-radius 0;
      padding:          $global-spacing-unit-small;
      margin-bottom:    0;
      font-family:      unquote($global-body-font);
      font-weight:      $regular;
      color:            mat.get-color-from-palette($red-purple, lighter-contrast);
      background-color: mat.get-color-from-palette($red-purple, lighter);

      @include mq($from: desktop) {
        @include font-size($global-beta-size);

        justify-content: flex-start;
        padding:         $global-spacing-unit;
      }
    }

    &__number {
      @include font-size($global-omega-size);

      display:       block;
      margin-bottom: $global-spacing-unit-small;
      font-weight:   $bold;
      color:         mat.get-color-from-palette($red-purple, default);
    }

    &__content {
      display:        flex;
      flex-direction: column;
      margin-bottom:  0;

      @include mq($from: desktop) {
        padding-left:      $global-spacing-unit;
        border-left-width: $global-spacing-unit-tiny;
        border-left-style: solid;
      }
    }

    &__link {
      font-weight: $bold;
      margin-top:  $global-spacing-unit;

      > fa-icon {
        margin-left: $global-spacing-unit-tiny;
        transition:  margin-left $global-transition $global-easing;
      }

      &:hover {
        text-decoration: none;

        > fa-icon { margin-left: $global-spacing-unit-small; }
      }
    }

  }

  .c-home-section__intro {
    margin-left: $global-spacing-unit-small;

    @include mq($from: desktop) {
      margin-left: 0;
    }
  }
}
