@import 'settings/index';
@import 'tools/index';


.c-edit-user {
  @include mq($from: desktop) {
    grid-template-columns: 2fr 1fr;
  }

  &__main {
    display:        flex;
    flex-direction: column;
    margin-bottom:  $global-spacing-unit-large;
  }

  &__form {
    display:               grid;
    grid-column-gap:       $global-spacing-unit;
    grid-template-columns: repeat(auto-fill, minmax($global-baseline * 60, 1fr));
    width:                 100%;
    margin-bottom:         0;
  }

  &__button-wrapper {
    display:         flex;
    grid-column:     1 / -1;
    justify-content: center;
    align-items:     center;
    margin-top:      $global-spacing-unit-large;
  }
}
