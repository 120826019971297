@import '~sass-mq/mq';


//__<<ngThemingMigrationEscapedComment0>>__

///
 // Mixin to set responsive values to a property based of a sass map
 // @param  { String }  $property - the css property to receive the value
 // @param  { Object }  $map      - the sass map  to get the values from.
 //                                 it should have a list of valid breakpoint
 //                                 names as its keys
 ///

@mixin responsive-property-from-map($property, $map, $default: 'phone') {

  #{$property}: map-get($map, $default);

  @each $bp-name, $value in $map {

    @if ($bp-name != $default) {

      @include mq($from: $bp-name) {
        #{$property}: $value;
      }

    }

  }

}
