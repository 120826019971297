@use '@angular/material' as mat;

@import '../settings/index';
@import '../tools/index';


@-webkit-keyframes medium-editor-image-loading {
  0%   { transform: scale(0); }
  100% { transform: scale(1); }
}

@keyframes medium-editor-image-loading {
  0%   { transform: scale(0); }
  100% { transform: scale(1); }
}

@keyframes medium-editor-pop-upwards {
  0%   { opacity: 0;  transform: matrix(0.97, 0, 0, 1, 0, 12); }
  20%  { opacity: .7; transform: matrix(0.99, 0, 0, 1, 0, 2); }
  40%  { opacity: 1;  transform: matrix(1, 0, 0, 1, 0, -1); }
  100% { transform: matrix(1, 0, 0, 1, 0, 0); }
}


.medium-editor {
  &-anchor-preview {
    @include font-size($global-gamma-size);

    position:    absolute;
    left:        0;
    top:         0;
    text-align:  center;
    word-break:  break-all;
    word-wrap:   break-word;
    visibility:  hidden;
    z-index:     2000;

    &-active { visibility: visible; }

    a {
      color:   $red-light-primary-text;
      display: inline-block;
      margin:  $global-spacing-unit-tiny $global-spacing-unit-tiny $global-spacing-unit-small;
    }
  }

  &-dragover { background: $red-dark-secondary-bg; }

  &-image-loading {
    animation:         medium-editor-image-loading 1s infinite $global-easing;
    background-color:  $red-dark-secondary-bg;
    border-radius:     100%;
    display:           inline-block;
    height:            $global-spacing-unit-large;
    width:             $global-spacing-unit-large;
  }

  &-placeholder {
    position: relative;

    &::after,
    &-relative::after {
      color:       mat.get-color-from-palette($red-primary, default);
      content:     attr(data-placeholder) !important;
      font-style:  italic;
      position:    relative;
      white-space: pre;
      padding:     inherit;
      margin:      inherit;
    }

    &:after {
      position: absolute;
      left:     0;
      top:      0;
    }

    &-relative {
      position: relative;
      &:after { position: relative; }
    }
  }

  &-action {
    @include font-size($global-delta-size);

    cursor:          pointer;
    display:         flex;
    align-items:     center;
    justify-content: center;
    margin:          0;
    text-decoration: none;
    min-width:       ($global-baseline * 15);
    height:          ($global-baseline * 5);
    border:          none;

    transition: background-color $global-transition $global-easing,
                color            $global-transition $global-easing;

    &:focus { outline: none; }

    &-underline { text-decoration: underline; }
    &-bold      { font-weight: $bold; }
    &-italic    { font-style: italic; }

    &-pre {
      font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace;
      font-weight: 100;
      padding:     $global-spacing-unit-small 0;
    }

    @each $type, $hue in $red-marks {

      &-proofreading-flag-#{$type} {
        background-color: mat.get-color-from-palette($hue, default);
        color:            $red-light-primary-text;

        &:hover {
          background-color: mat.get-color-from-palette($hue, darker);
        }
      }
    }
  }

  &-button-first { border-radius: $global-radius $global-radius 0 0; }
  &-button-last  { border-radius: 0 0 $global-radius $global-radius; }

  @include mq($from: tablet-landscape) {
    &-button-first { border-radius: $global-radius 0 0 $global-radius; }
    &-button-last  { border-radius: 0 $global-radius $global-radius 0; }
  }

  &-toolbar {
    @include font-size($global-gamma-size);

    position:    absolute;
    left:        0;
    top:         0;
    visibility:  hidden;
    z-index:     10;

    &-active {
      visibility: visible;

      &.medium-editor-stalker-toolbar {
        animation: medium-editor-pop-upwards 160ms forwards linear;
      }
    }

    &-actions::after {
      clear:   both;
      content: '';
      display: table;
    }

    &-actions,
    &-actions li {
      margin:     0;
      padding:    0;
      list-style: none;

      @include mq($from: tablet-landscape) {
        float: left;
      }
    }

    &-form {
      display:       none;
      border-radius: $global-radius;
      max-width:     calc(100vw - #{ $global-baseline * 4 });
      margin:        0 ($global-baseline * 3) 0 $global-baseline;

      a {
        display:         inline-block;
        font-size:       24px;
        font-weight:     bolder;
        margin:          0 10px;
        text-decoration: none;
        color:           $red-light-primary-text;
      }

      &-row {
        line-height:    14px;
        margin-left:    5px;
        padding-bottom: 5px;
      }

      &-active { display: block; }
    }

    &-input {
      @mixin _editor-input-placeholder { color: rgba($red-light-primary-text, 0.87); }

      &::-webkit-input-placeholder { @include _editor-input-placeholder; }
      &:-moz-placeholder           { @include _editor-input-placeholder; }
      &::-moz-placeholder          { @include _editor-input-placeholder;} /* Firefox 18- */
      &:-ms-input-placeholder      { @include _editor-input-placeholder; } /* Firefox 19+ */
    }

    &-form &-input {
      height:     ($global-baseline * 18);
      width:      100vw !important;
      color:      $red-light-primary-text;
      background: transparent;
    }

    &-form &-input,
    &-form label {
      @include font-size($global-delta-size);

      display: inline-block;
      border:  none;
      width:   ($global-baseline * 60);
      margin:  0;
      padding: $global-spacing-unit-tiny
               ($global-baseline * 10)
               $global-spacing-unit-tiny
               $global-spacing-unit-small;

      &:focus {
        appearance: none;
        border:     none;
        box-shadow: none;
        outline:    0;
      }
    }

    &-anchor-preview {
      background: $red-base-link-color;
      color:      $red-light-primary-text;
    }
  }

  &-element {
    word-wrap:  break-word;
    min-height: ($global-baseline * 15);

    img { max-width: 100%; }
    sub { vertical-align: sub; }
    sup { vertical-align: super; }
  }

  &-hidden           { display: none; }
  &-sticky-toolbar   { position: fixed; top: 1px; }
  &-relative-toolbar { position: relative; }
}


.c-editor {
  &__arrow {
    position:       absolute;
    top:            100%;
    left:           50%;
    height:         0;
    width:          0;
    border-style:   solid;
    border-width:   $global-spacing-unit-small;
    transform:      translate(-50%, 0);
    z-index:        1;
  }

  @each $type, $hue in $red-marks {

    &--#{$type} &__arrow {
      border-color:     transparent;
      border-top-color: mat.get-color-from-palette($hue, darker);
    }
  }

  &__flag-selector {
    display:    flex;
    color:      inherit;
    width:      100%;
    max-width:  ($global-baseline * 70);
    max-height: ($global-baseline * 52);
    overflow-x: hidden;
    overflow-y: auto;

    > .c-mark__flag-info-button--close { right: $global-baseline; }
  }

  &__types {
    width:     50%;
    display:   flex;
    flex-wrap: wrap;
    padding:   $global-spacing-unit-tiny 0;
    margin:    0;
  }

  &__types-item {
    @include font-size($global-epsilon-size);

    text-transform: uppercase;
    font-weight:    $bold;
    display:        block;
    padding:        $global-spacing-unit-tiny $global-spacing-unit-small;
    cursor:         pointer;
    width:          100%;
    white-space:    normal;
    flex-shrink:    0;
    flex-grow:      1;
  }

  &__description {
    @include font-size($global-delta-size, $modifier: -1);

    position: sticky;
    top:      0;
    width:    50%;
    padding:  $global-spacing-unit-small;
  }
}
