@import '../settings/index';
@import '../tools/index';

.c-modal {
  //pointer-events: auto;

  &__title {
    text-transform: uppercase;
    font-weight:    $bold;
  }

  &__footer {
    padding: $global-spacing-unit-small 0 $global-spacing-unit-tiny;
  }
}
