@import '@angular/material/theming';

@import '../settings/index';
@import '../tools/tools.color-property-by-theme';


@mixin red-hamburger-menu-theme($theme) {

  .c-hamburger-menu {

    &__button {
      @include color-properties-by-theme($theme, (
        'background-color': default,
        'color':            default-contrast
      ));

      border:           0 none;
      text-transform:   uppercase;
      font-weight:      $bold;
      letter-spacing:   2px;
      outline:          none;

      transition:
        background-color ($global-transition * 0.5) $global-easing;

      &:hover {
        @include color-properties-by-theme($theme, 'background-color', darker);
      }
    }

      &__icon-bar {
        @include color-properties-by-theme($theme, 'background-color', default-contrast);
      }

    &__drawer {
      @include color-properties-by-theme($theme, 'background-color', default);
    }

  }
}
