@use '@angular/material' as mat;
@import '../settings/index';
@import '../tools/index';


$_block: '.c-card';

.c-card {
  @include z-depth(3);

  position:         relative;
  display:          flex;
  flex-direction:   column;
  flex:             1 0 auto;
  overflow:         hidden;
  height:           100%;
  padding:          $global-spacing-unit-small;
  border:           1px solid $red-dark-dividers;
  border-radius:    $global-radius;
  background-color: $red-light-primary-bg;
  cursor:           pointer;
  transition:       box-shadow $global-transition ease-in-out;

  &:hover { @include z-depth(5); }

  &__link {
    display:        flex;
    flex-direction: column;
    flex:           1 0 auto;
    padding:        $global-spacing-unit-small;
    margin:         ($global-spacing-unit-small * -1);

    &, &:hover {
      text-decoration: none;
      color:           inherit;
    }
  }


  &__image {
    width:         100%;
    margin-bottom: $global-spacing-unit-small;

    > img { width: 100%; }
  }

  &__flag {
    @include z-depth(2);
    @include font-size($global-delta-size);

    position:         absolute;
    top:              $global-spacing-unit;
    left:             0;
    padding:          ($global-baseline * 0.5) $global-spacing-unit-small;
    border-radius:    0 $global-baseline $global-baseline 0;
    font-weight:      $bold;
    background-color: $red-light-secondary-bg;
    color:            $red-dark-primary-text;
  }

  @each $type, $hue in $red-categories {
    &--#{$type} &__flag {
      background-color: mat.get-color-from-palette($hue, default);
      color:            mat.get-color-from-palette($hue, default-contrast);
    }
  }

  &__pills {
    position: absolute;
    right:    $global-spacing-unit-tiny;
    bottom:   $global-spacing-unit-tiny;
  }

  &__pill {
    border:      1px solid;
    margin-left: $global-spacing-unit-small;
  }

  &__exclude {
    position:        absolute;
    top:             $global-spacing-unit;
    right:           ($global-baseline * 3);
    display:         flex;
    align-items:     center;
    justify-content: center;
    width:           ($global-baseline * 5);
    height:          ($global-baseline * 5);
    padding:         $global-spacing-unit-tiny;
    border:          none;
    border-radius:   100%;
    overflow:        hidden;
    outline:         none;
    cursor:          pointer;
    z-index:         1;
  }

  @each $type, $hue in $red-categories {
    &--#{$type} &__exclude {
      background-color: mat.get-color-from-palette($hue, default-contrast);
      color:            mat.get-color-from-palette($hue, default);

      transition:       background-color $global-transition $global-easing,
                        color $global-transition $global-easing;

      &:hover { color: mat.get-color-from-palette($hue, darker); }
    }
  }

  &--inactive {
    opacity: .75;
    filter:  grayscale(1);
  }

  &__content {
    display:        flex;
    flex-direction: column;
    flex-grow:      1;
  }

  &__title, &_summary {
    @include font-size($global-epsilon-size, $modifier: -1);
  }

  &__title {
    display:         flex;
    flex-direction:  column;
    flex-grow:       1;
    justify-content: center;
    margin-bottom:   $global-spacing-unit-small;
  }

  &__summary {
    @include flush($global-spacing-unit-small, true);

    width:            auto;
    padding:          $global-spacing-unit-small;
    margin-top:       auto;
    margin-bottom:    0;
    background-color: $red-light-secondary-bg;

    @include mq($from: tablet-landscape) {
      @include font-size($global-delta-size);
    }

    &-title {
      margin-bottom: $global-spacing-unit-small;
    }

    &-item {
      margin-bottom: $global-spacing-unit-tiny;

      @include mq($from: tablet-landscape) {
        margin-bottom: 0;
      }

      > strong {
        display: block;

        @include mq($from: tablet-landscape) {
          display: inline;
        }
      }
    }

  }

  &__button {
    flex-shrink: 0;
    width:       100%;
    padding:     $global-spacing-unit-tiny 0;
  }
}


// flip

.c-flip-card {
  height:      100%;
  perspective: 1000px;

  .flipped > & &__wrapper,
  &:hover &__wrapper,
  &:focus-within &__wrapper {
    transform: rotateY(180deg);
  }

  .flipped > &--inline &__wrapper,
  &--inline:hover &__wrapper,
  &--inline:focus-within &__wrapper {
    transform: rotateX(180deg);
  }

  &__wrapper {
    position:        relative;
    display:         flex;
    flex-direction:  column;
    width:           100%;
    height:          100%;
    transition:      transform $global-transition * 2 $global-easing;
    transform-style: preserve-3d;
  }

  &__face {
    backface-visibility: hidden;

    &-link {
      display:         flex;
      justify-content: center;
      align-items:     center;
      height:          100%;
      overflow:        hidden
    }
  }


  &__front {
    position:       relative;
    pointer-events: none;
  }

  &__back {
    position:  absolute;
    top:       0;
    right:     0;
    bottom:    0;
    left:      0;
    transform: rotateY(180deg);
  }

  &__back &__content {
    height: 100%;
    width:  100%;
  }
}


// inline

.c-card,
.c-flip-card {

  &--inline {
    min-height: ($global-baseline * 25);
  }

  &--inline &__main {
    display: grid;
    width:   100%;
    margin:  0;
    padding: 0;

    @include mq($from: tablet-landscape) {
      grid-template-columns: ($global-baseline * 40) auto;
    }
  }

  &--inline &__flag {
    @include font-size($global-epsilon-size);

    top: $global-spacing-unit-small;
  }

  &--inline &__image {
    display:       none;
    flex:          0 0 auto;
    width:         100%;
    margin:        0;
    padding:       0;
    border-radius: $global-radius 0 0 $global-radius;

    > img { object-fit: cover; }

    @include mq($from: tablet-landscape) {
      display: block;
    }
  }

  &--inline &__content {
    display:     block;
    margin:      $global-spacing-unit-tiny $global-spacing-unit-small;
    padding-top: ($global-baseline * 7);

    @include mq($from: tablet-landscape) {
      padding-top: $global-spacing-unit-small;
    }
  }

  &--inline &__summary {
    height:  100%;
    padding: $global-spacing-unit-tiny $global-spacing-unit-small;

    &:last-child { margin-bottom: ($global-spacing-unit-tiny * -1); }

    &-title {
      width:         100%;
      margin-bottom: $global-spacing-unit-tiny;
    }

    &-item {
      display:       flex;
      flex-wrap:     wrap;
      padding-right: $global-spacing-unit;

      &:last-child { margin-bottom: 0; }

      > strong { margin-right: $global-spacing-unit-tiny; }
    }
  }

  &--inline &__button {
    padding: $global-spacing-unit-tiny $global-spacing-unit;
  }
}


.c-card {
  &--inline {
    @include z-depth(1);

    flex-direction: row;
    padding:        0;

    &:hover {
      @include z-depth(3);
    }
  }

  &--inline &__title {
    display:      inline;
    margin-right: $global-spacing-unit-small;
  }
}


.c-flip-card {
  &--inline &__back {
    transform: rotateX(180deg);
  }

  &--inline &__back &__content {
    display:               grid;
    grid-gap:              0 $global-spacing-unit;
    grid-template-columns: minmax(0, 1fr) auto;
    grid-template-rows:    auto minmax(0, 1fr);
    padding:               0;
    margin:                0;
  }

  &--inline &__back &__action {
    grid-column:     2;
    grid-row:        1 / -1;
    display:         flex;
    justify-content: center;
    align-items:     flex-end;
  }
}
