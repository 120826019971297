@import '../settings/index';


.c-dropdown-menu {
  //pointer-events: auto;

  .mat-menu-content:not(:empty) {
    padding-top:    $global-spacing-unit-tiny;
    padding-bottom: $global-spacing-unit-tiny;
  }

  &__title,
  & &__item {
    @include font-size($global-delta-size);

    height:    auto;
    min-width: ($global-spacing-unit * 8);
    padding:   $global-spacing-unit-tiny $global-spacing-unit;
    color:     $red-dark-primary-text;

    &:hover { text-decoration: none; }
  }

  &__title {
    display:       block;
    border-bottom: 1px solid $red-dark-dividers;
    font-weight:   $bold;

    &:first-child { margin-top: ($global-spacing-unit-tiny * -1); }
  }
}
