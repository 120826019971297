@import '../settings/settings.global';
@import '../tools/tools.icon-size';


//__<<ngThemingMigrationEscapedComment0>>__


// sizes

.u-icon-tiny    { @include icon-size($global-spacing-unit-tiny,   $important: true); }
.u-icon-small   { @include icon-size($global-spacing-unit-small,  $important: true); }
.u-icon-medium  { @include icon-size($global-spacing-unit,        $important: true); }
.u-icon-large   { @include icon-size($global-spacing-unit-large,  $important: true); }
.u-icon-huge    { @include icon-size($global-spacing-unit-huge,   $important: true); }


// icon color utilities are defined in _themes.icon.scss
