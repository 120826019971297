@use 'sass:selector';

@use '@angular/material' as mat;
@import '@angular/material/theming';


@function map-mat-theme($mat-theme) {

  @return (
    'primary': map-get($mat-theme, primary),
    'accent':  map-get($mat-theme, accent),
    'warn':    map-get($mat-theme, warn)
  );
}


@mixin color-properties-by-theme(
  $theme-map,
  $properties,
  $hue: null,
  $alpha: null
) {

  $_namespace: map-get($theme-map, namespace);
  $_palettes:  if($_namespace == null, map-mat-theme($theme-map), map-get($theme-map, palettes));

  @if ($_namespace != null) { $_namespace: '#{$_namespace}-'; }

  @each $type, $color in $_palettes {

    $_selector: '.t-#{$_namespace}#{$type}';

    $_double-selector: selector.append($_selector, $_selector);

    #{ $_selector } &,
    &#{ $_double-selector } {

      @each $property, $_hue in $properties {
        $_alpha: if(length($_hue) == 2, nth($_hue, 2), $alpha);
        $_hue:   if($_hue != null, nth($_hue, 1), $hue);

        #{ $property }: mat.get-color-from-palette($color, $_hue, $_alpha);
      }
    }
  }

}
