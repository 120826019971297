/* ==========================================================================
   #HACKS
   ========================================================================== */


/* Gas Tecnologia Object [_hack]
   ========================================================================== */

/**
 * This object tag is inserted by protection bank plugins developed
 * by Gas Tecnologia, a brazilian company, expert in internet security.
 * This company provides service to the banks: Banco do Brasil, Caixa Econômica
 * Federal, Itaú, Santander, Banco Mercantil do Brasil, Banco da Amazônia,
 * Banestes and Tecnocred/Unicred. This is a serious bug, that causes a blank
 * space to appear after the body tag.
 */

object[type^='application/gas-events'] {
  display: none;
}


/* 0x0 Iframe [_hack]
   ========================================================================== */

/**
 * Similar to the object above, some third parts will add a 0 width, 0 height
 * iframe to the bottom of the page, and it could result in strange blank lines.
 */

iframe[width='0'][height='0'] {
  display: none;
}


/* mat-button-wrapper proper center
   ========================================================================== */

/**
 * Properly align icons in mat-buttons
 */

.mat-button-wrapper {
  display:         flex;
  align-items:     center;
  justify-content: center;
}


/* mat-radio-group wrap text and re-align
   ========================================================================== */

mat-radio-group.u-text-wrap {
  .mat-radio-container {
    /* The default behavior is center. */
    /* But the radio options are then difficult to tell apart. */
    /* Therefore we align the radio symbol on top position. */
    align-self:         start;
    margin-block-start: 2px;
  }

  .mat-radio-label-content {
    /* Wrap the label text. */
    white-space:          normal;
    padding-inline-start: 12px;
  }
}


/* position the rybena plugin's sidebar
   ========================================================================== */

#rybena-sidebar {
  top:    auto !important;
  bottom: 24px !important;
}


/* force hidden attribute to work properly
   ========================================================================== */

[hidden] { display: none !important;}
