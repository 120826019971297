@use '@angular/material' as mat;

@import '../settings/index';
@import '../tools/index';


.c-accordion {
  &-icon {
    position:         relative;
    display:          block;
    flex-grow:        0;
    flex-shrink:      0;
    align-self:       center;
    margin-left:      auto;
    width:            $global-delta-size;
    height:           $global-delta-size;
    border-radius:    100%;
    cursor:           pointer;

    &::before,
    &::after {
      content:          '';
      display:          block;
      position:         absolute;
      top:              50%;
      left:             50%;
      width:            50%;
      height:           12.5%;
      opacity:          1;
      background-color: mat.get-color-from-palette($red-accent, default);

      transition:
        opacity   $global-transition ease-out,
        transform $global-transition ease-out;
    }

    &::before { transform: translate(-50%, -50%) rotate(0); }
    &::after  { transform: translate(-50%, -50%) rotate(90deg); }

    .mat-expanded & {
      &::before { transform: translate(-50%, -50%) rotate(90deg); opacity: 0; }
      &::after  { transform: translate(-50%, -50%) rotate(180deg); }
    }
  }

  &,
  .mat-expansion-panel-header {
    @include font-size($global-delta-size);

    // fix weird color behavior on mobile, when coloring the panel-header
    &:hover { background-color: transparent !important; }
  }

  &--drawer {
    font-weight: $regular;

    .mat-expansion-panel-body { padding: 0; }
  }

    &--drawer &__menu { margin-bottom: 0; }


    /**************************
    * [1] align the text with the title after its icon.
    * [2] the .mat-expansion-panel breaks the box-sizing inheritance
    *     by setting it to content-box, so we reset it here.
    */

    $_accordion-padding:     $global-spacing-unit;
    $_accordion-icon-width:  $global-spacing-unit;
    $_accordion-icon-margin: $global-spacing-unit-small;
    $_accordion-indent:      $_accordion-padding + $_accordion-icon-width + $_accordion-icon-margin;

    &--drawer &__title {
      font-weight:   $bold;
      padding-left:  $_accordion-padding;
      padding-right: $_accordion-padding;

      &--indent { padding-left: $_accordion-indent; } // [1]

      &-icon {
        width:        $_accordion-icon-width;
        margin-right: $_accordion-icon-margin;
      }
    }

    &--drawer &__link {
      box-sizing:     border-box; // [2]
      display:        block;
      width:          100%;
      padding-top:    $global-spacing-unit-small;
      padding-right:  $_accordion-padding;
      padding-left:   $_accordion-indent; // [1]
      padding-bottom: $global-spacing-unit-small;

      &:hover { text-decoration: none; }
    }
}

.c-accordion-aside {

  & &__header {
    padding-left:  0;
    margin-bottom: $global-spacing-unit-small;
    color:         mat.get-color-from-palette($red-primary, default);
  }

  .t-neutral &__title {
    color: $red-dark-primary-text;
  }

  & &__panel,
  &__divider {
    margin-bottom: $global-spacing-unit !important;
    border-bottom: 1px solid $red-dark-dividers;
  }

  &__divider {
    border-top:   none;
    border-left:  none;
    border-right: none;
  }

  .mat-expansion-panel-body {
    @include font-size($global-delta-size);

    padding: 0 $global-spacing-unit $global-spacing-unit-small 0;

    @include mq($from: tablet-portrait) {
      padding-right: 0;
    }
  }
}
