@use "sass:math";

@import '../settings/index';


/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist without unit.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */

html {
  font-size:        math.div($global-font-size, 16px) * 1em;         /* [1] */
  line-height:      math.div($global-line-height, $global-font-size);  /* [1] */
  overflow-y:       scroll; /* [2] */
  min-height:       100%;   /* [3] */
  scroll-behavior:  smooth;
  box-sizing:       border-box;
  background-color: $red-light-secondary-bg;
  background-image: linear-gradient(135deg, #ababab 0%, #e0e0e0 50%, #ababab 100% );
}

iframe { border: none }
