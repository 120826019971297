@import '../settings/index';
@import '../tools/tools.icon-size';


/* ==========================================================================
   #AVATAR
   ========================================================================== */

/**
 * Avatar/user-image-like object, with image on a circle container,
 * with fallback image.
 */

.o-avatar {
  position:        relative;
  display:         flex;
  align-items:     center;
  justify-content: center;
  margin-bottom:   0;

  img {
    max-width:       100%;
    max-height:      100%;
    object-fit:      cover;
    object-position: center;
  }

  &__image {
    position:        relative;
    width:           100%;
    border-radius:   100%;
    object-fit:      cover;
    object-position: center center;
    overflow:        hidden;
    display:         flex;
    align-items:     center;
    justify-content: center;
    z-index:         1;

    &--default {
      position: absolute;
      z-index:  0;
    }
  }

}
