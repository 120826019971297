@function get-svg-string($name, $color) {

  $_svg-flag: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">' +
              '<path fill="#{$color}" d="M349.565 98.783C295.978 98.783 251.721 64 ' +
              '184.348 64c-24.955 0-47.309 4.384-68.045 12.013a55.947 55.947 0 0 0 ' +
              '3.586-23.562C118.117 24.015 94.806 1.206 66.338.048 34.345-1.254 8 24.296 ' +
              '8 56c0 19.026 9.497 35.825 24 45.945V488c0 13.255 10.745 24 24 24h16c13.255 ' +
              '0 24-10.745 24-24v-94.4c28.311-12.064 63.582-22.122 114.435-22.122 53.588 0 ' +
              '97.844 34.783 165.217 34.783 48.169 0 86.667-16.294 122.505-40.858C506.84 ' +
              '359.452 512 349.571 512 339.045v-243.1c0-23.393-24.269-38.87-45.485-29.016-34.338 ' +
              '15.948-76.454 31.854-116.95 31.854z"></path></svg>';

  $_svg-trash: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">' +
               '<path fill="#{$color}" d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 ' +
               '48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 ' +
               '16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 ' +
               '1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 ' +
               '0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 ' +
               '0 0 0 16-16V48a16 16 0 0 0-16-16z"></path></svg>';

  $_svg-close: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">' +
               '<path fill="#{$color}" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 ' +
               '0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 ' +
               '256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 ' +
               '406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>';

  $_svg-check: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">' +
               '<path fill="#{$color}" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 ' +
               '256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 ' +
               '45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>';

  $_svg-edit: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">' +
              '<path fill="#{$color}" d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 ' +
              '485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 ' +
              '128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"></path></svg>';

  $_svg-auto: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">' +
              '<path fill="#{$color}" d="M224 96l16-32 32-16-32-16-16-32-16 32-32 ' +
              '16 32 16 16 32zM80 160l26.66-53.33L160 80l-53.34-26.67L80 0 53.34 53.33 ' +
              '0 80l53.34 26.67L80 160zm352 128l-26.66 53.33L352 368l53.34 26.67L432 ' +
              '448l26.66-53.33L512 368l-53.34-26.67L432 288zm70.62-193.77L417.77 9.38C411.53 ' +
              '3.12 403.34 0 395.15 0c-8.19 0-16.38 3.12-22.63 9.38L9.38 372.52c-12.5 12.5-12.5 ' +
              '32.76 0 45.25l84.85 84.85c6.25 6.25 14.44 9.37 22.62 9.37 8.19 0 16.38-3.12 ' +
              '22.63-9.37l363.14-363.15c12.5-12.48 12.5-32.75 0-45.24zM359.45 203.46l-50.91-50.91 ' +
              '86.6-86.6 50.91 50.91-86.6 86.6z"></path></svg>';

  $_svg-comments: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">' +
                  '<path fill="#{$color}" d="M448 0H64C28.7 0 0 28.7 0 64v288c0 ' +
                  '35.3 28.7 64 64 64h96v84c0 9.8 11.2 15.5 19.1 9.7L304 416h144c35.3 ' +
                  '0 64-28.7 64-64V64c0-35.3-28.7-64-64-64z"></path></svg>';

  $_svg-abacus: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">' +
                '<path fill="#{$color}" d="M552 0c-13.25 0-24 10.74-24 ' +
                '24v48h-48V48c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 ' +
                '16v24H240V48c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 ' +
                '16v24h-48V48c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 ' +
                '16v24H48V24C48 10.74 37.25 0 24 0S0 10.74 0 24v476c0 6.63 ' +
                '5.37 12 12 12h24c6.63 0 12-5.37 12-12v-60h48v24c0 8.84 7.16 ' +
                '16 16 16h16c8.84 0 16-7.16 16-16v-24h48v24c0 8.84 7.16 16 16 ' +
                '16h16c8.84 0 16-7.16 16-16v-24h192v24c0 8.84 7.16 16 16 16h16c8.84 ' +
                '0 16-7.16 16-16v-24h48v60c0 6.63 5.37 12 12 12h24c6.63 0 12-5.37 ' +
                '12-12V24c0-13.26-10.75-24-24-24zM96 120v24c0 8.84 7.16 16 16 ' +
                '16h16c8.84 0 16-7.16 16-16v-24h48v24c0 8.84 7.16 16 16 16h16c8.84 ' +
                '0 16-7.16 16-16v-24h192v24c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 ' +
                '16-16v-24h48v112H336v-24c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 ' +
                '16v24h-48v-24c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 ' +
                '16v24h-48v-24c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 ' +
                '16v24H48V120h48zm384 272v-24c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 ' +
                '16v24H240v-24c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 ' +
                '16v24h-48v-24c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 ' +
                '16v24H48V280h48v24c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 ' +
                '16-16v-24h48v24c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-24h48v24c0 ' +
                '8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-24h192v112h-48z"></path></svg>';

  $_svg-string-map: (
    'flag':     $_svg-flag,
    'trash':    $_svg-trash,
    'close':    $_svg-close,
    'check':    $_svg-check,
    'edit':     $_svg-edit,
    'auto':     $_svg-auto,
    'comments': $_svg-comments,
    'abacus':   $_svg-abacus
  );

  @return map_get($_svg-string-map, $name);
}

@mixin svg-background($svg-name, $color: currentColor) {

  $_safe-color: $color;

  @if ($color != currentColor) {
    $_safe-color: unquote("rgba(#{red($color)}, #{green($color)}, #{blue($color)}, #{alpha($color)})");
  }

  $_svg-string: get-svg-string($svg-name, $_safe-color);

  background-image: url('data:image/svg+xml;utf-8,' + $_svg-string);
}
