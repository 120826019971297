@import 'settings/index';
@import 'tools/index';


.c-loading {
  display:         flex;
  justify-content: center;
  align-items:     center;

  position: fixed;
  top:      0; right: 0;
  bottom:   0; left:  0;
  z-index:  10;

  background-color: rgba($red-light-primary-bg, .21);
}
