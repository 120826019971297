@import '../settings/index';


/* ==========================================================================
   #DATA-LIST
   ========================================================================== */

/**
 * Most common usages of dl, dd and dt tags
 */

.o-data-list {
  display:               grid;
  grid-column-gap:       $global-spacing-unit-small;
  grid-row-gap:          $global-spacing-unit-tiny * 0.5;
  grid-template-columns: fit-content(50%) 1fr;

  &__title,
  &__item {
    margin-left: 0;
  }

  &__title { grid-column: 1; font-weight: $bold; }
  &__item  { grid-column: 2; font-weight: $regular; }

  @media print {
    display:       block;
    margin-bottom: $global-spacing-unit-tiny;
    text-align:    center;

    &__title,
    &__item {
      display: inline;
    }

    &__title {
      &::after { content: ' '; white-space: pre-wrap; }
    }

    &__item {
      display:         inline-flex;
      justify-content: flex-start;
      align-items:     flex-end;

      &::after            { content: ' | '; display: inline; }
      &:last-child::after { content: '';    display: block; }
    }
  }
}

