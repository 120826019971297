@use '@angular/material' as mat;
@import 'settings/index';
@import 'tools/index';


.c-cover {
  display:               grid;
  grid-column-gap:       $global-spacing-unit-small;
  grid-row-gap:          $global-spacing-unit;
  grid-template-rows:    1fr max-content max-content max-content 1fr;
  grid-template-columns: $global-spacing-unit-small repeat(2, 1fr) $global-spacing-unit-small;
  min-height:            100vh;

  background-size:  400% 400%;
  background-image: linear-gradient(135deg,
    mat.get-color-from-palette($red-primary, darker) 10%,
    mat.get-color-from-palette($red-purple, default) 50%,
    mat.get-color-from-palette($red-accent, default) 75%,
    mat.get-color-from-palette($red-blue, darker) 100%
  );

  transition: background-position .33s ease-in-out;


  @include mq($from: tablet-portrait) {
    grid-column-gap:       $global-spacing-unit;
    grid-template-columns: minmax($global-spacing-unit-huge, 1fr)
                           max-content repeat(2, 1fr)
                           minmax($global-spacing-unit-huge, 1fr);
  }

  @include mq($from: desktop) {
    grid-template-columns: minmax($global-spacing-unit-huge, 1fr)
                           1fr max-content repeat(2, 1fr)
                           minmax($global-spacing-unit-huge, 1fr);
  }


  &__title-qualifier,
  &__main-title {
    position: relative;
  }

  &__image {
    grid-row:              1 / 6;
    grid-column:           1 / 3;
    width:                 100%;
    height:                100%;
    background-size:       cover;
    background-blend-mode: multiply;

    @include mq($from: desktop) {
      grid-column: 1 / 4;
    }
  }

  &__title-qualifier {
    @include font-size($global-delta-size);
    @include responsive-property-from-map('margin-top', $toolbar-institutional-height);

    grid-row:        2;
    grid-column:     2;
    display:         flex;
    justify-content: flex-start;
    align-items:     flex-end;
    padding-right:   $global-spacing-unit;
    padding-left:    $global-spacing-unit;
    font-family:     unquote($global-body-font);
    text-transform:  uppercase;
    white-space:     nowrap;
    font-weight:     $bold;

    @include mq($from: tablet-portrait) {
      @include font-size($global-gamma-size);

      padding-right: $global-spacing-unit-large;
      padding-left:  $global-spacing-unit-large;
    }

    @include mq($from: desktop) {
      grid-column: 3;
    }
  }

  &__main-title {
    @include font-size($global-alpha-size + 4);

    grid-row:        3;
    grid-column:     2 / 4;
    padding-left:    $global-spacing-unit;
    margin-bottom:   0;
    display:         flex;
    flex-direction:  column;
    justify-content: flex-end;
    position:        relative;

    @include mq($from: tablet-portrait) {
      @include font-size($global-omega-size - 8, $modifier: 1);

      grid-column:  2 / 5;
      padding-left: $global-spacing-unit-large;
    }

    @include mq($from: desktop) {
      @include font-size($global-omega-size, $modifier: 1);

      grid-column: 3 / 6;
    }
  }

  &__controls {
    grid-row:    4;
    grid-column: 2 / 4;
    display:     grid;
    place-items: flex-end;

    @include mq($from: tablet-portrait) {
      grid-column: 2 / 5;
    }

    @include mq($from: desktop) {
      grid-column: 4 / 6;
    }
  }

  &__button {
    @include font-size($global-delta-size);

    font-weight:   $bold;
    padding-left:  $global-spacing-unit-large;
    padding-right: $global-spacing-unit-large;
    white-space:   nowrap;

    @include mq($from: tablet-portrait) {
      @include font-size($global-gamma-size + 4);
    }
  }


  /* Reversed cover grid */

  &--reversed {
    @include mq($from: tablet-portrait) {
      grid-template-columns: minmax($global-spacing-unit-huge, 1fr)
                             repeat(2, 1fr) max-content
                             minmax($global-spacing-unit-huge, 1fr);
    }

    @include mq($from: desktop) {
      grid-template-columns: minmax($global-spacing-unit-huge, 1fr)
                             repeat(2, 1fr) max-content 1fr
                             minmax($global-spacing-unit-huge, 1fr);
    }
  }

  &--reversed &__image { grid-column: 3 / 5; }

  &--reversed &__title-qualifier { grid-column: 3; }

  @include mq($from: tablet-portrait) {
    &--reversed &__image { grid-column: 4 / 7; }
    &--reversed &__title-qualifier { grid-column: 4; }
  }

  &--reversed &__main-title {
    grid-column:   2 / 4;
    padding-left:  0;
    padding-right: $global-spacing-unit;
    text-align:    end;

    @include mq($from: tablet-portrait) {
      grid-column:   2 / 5;
      padding-right: $global-spacing-unit-large;
      margin-bottom: $global-spacing-unit;
    }
  }

  &--reversed &__controls { grid-column: 2 / 4; }

  @include mq($from: tablet-portrait, $until: desktop) {
    &--reversed &__controls { grid-column: 2 / 5; }
  }


  // basic

  $_mobile-image-row-height: ($global-spacing-unit-huge * 3);

  &--basic {
    grid-template-rows: $global-spacing-unit-huge
                        max-content
                        $_mobile-image-row-height
                        max-content
                        $global-spacing-unit-huge;

    @include mq($from: tablet-landscape) {
      grid-column-gap:       $global-spacing-unit-large;
      grid-template-columns: minmax($global-spacing-unit-huge, 1fr)
                             repeat(3, 1fr)
                             $global-spacing-unit-huge;

      grid-template-rows: minmax($global-spacing-unit-huge, 1fr)
                          repeat(2, max-content)
                          minmax($global-spacing-unit-huge, 1fr);
    }

    @include mq($from: desktop) {
      grid-template-columns: minmax($global-spacing-unit-huge, 1fr)
                             repeat(4, 1fr)
                             minmax($global-spacing-unit-huge, 1fr);
    }
  }

  &--basic &__image {
    grid-row:    2 / 4;
    grid-column: 1 / 5;
    height:      $_mobile-image-row-height + ($global-spacing-unit * 2);
    margin-top:  auto;

    @include mq($from: tablet-portrait) {
      grid-column: 1 / 6;
    }

    @include mq($from: tablet-landscape) {
      grid-row:    1 / 5;
      grid-column: 1 / 3;
      height:      100%;
      margin:      0;
    }

    @include mq($from: desktop) {
      grid-column: 1 / 4;
    }
  }

  &--basic &__main-title,
  &--basic &__content {
    grid-column: 2 / 4;

    @include mq($from: tablet-portrait) {
      grid-column: 2 / 5;
    }

    @include mq($from: tablet-landscape) {
      grid-column: 3 / 5;
    }

    @include mq($from: desktop) {
      grid-column: 4 / 6;
    }

    > p {
      margin-bottom: $global-spacing-unit;
    }
  }

  &--basic &__main-title {
    @include font-size($global-omega-size);
    @include responsive-property-from-map('margin-top', $toolbar-institutional-height);

    grid-row: 2;
    padding:  0;

    @include mq($from: tablet-landscape) {
      margin-left: ($global-spacing-unit * -2);
    }
  }

  &--basic &__content {
    grid-row: 4;

    @include mq($from: tablet-landscape) {
      grid-row: 3;
    }
  }
}
