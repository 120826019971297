@use 'sass:math';

@import '../settings/index';


/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

$_button-border-width: 2px;

@function subtractBorders($value, $border-width) {
  @return $value - $border-width;
}


.c-button {
  padding-block:  subtractBorders($global-spacing-unit-tiny, $_button-border-width);
  padding-inline: subtractBorders($global-spacing-unit, $_button-border-width);
  border-radius:  $global-baseline;
  text-transform: uppercase;
  outline:        none;
  display:        inline-block; /* [1] */
  vertical-align: middle; /* [2] */
  font:           inherit; /* [3] */
  text-align:     center; /* [4] */
  margin:         0; /* [4] */
  cursor:         pointer; /* [5] */
  min-width:      ($global-baseline * 11);
  border-width:   $_button-border-width;
  border-style:   solid;
  border-color:   currentColor;

  &:hover {
    background-image: none;
    text-decoration:  none;
  }

  &[disabled] {
    border-color: transparent;
  }


  // Sizes

  &--small {
    @include font-size($global-delta-size);

    padding-block:  subtractBorders($global-spacing-unit-tiny * 0.5, $_button-border-width);
    padding-inline: subtractBorders($global-spacing-unit-small, $_button-border-width);
  }

  &--large {
    @include font-size($global-gamma-size);

    padding-block:  subtractBorders($global-spacing-unit-small, $_button-border-width);
    padding-inline: subtractBorders($global-spacing-unit-huge, $_button-border-width);
  }
}
