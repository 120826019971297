@use '@angular/material' as mat;
@import '../settings/index';


.o-backdrop {
  background-image: linear-gradient(135deg,
    rgba($red-light-primary-bg, 0.87) 0%,
    darken($red-light-secondary-bg, 5%) 100%
                    );

  &--dark {
    background-image: linear-gradient(135deg,
      rgba($red-dark-primary-bg, 0.22) 0%,
      rgba($red-dark-primary-bg, 0.11) 100%
                      );
  }

  &--transparent {
    background-color: rgba($red-light-primary-bg, 0);
    background-image: none;
  }

  &__close-btn {
    @include font-size($global-beta-size);

    position:       absolute;
    top:            $global-spacing-unit-small;
    right:          $global-spacing-unit-small;
    outline:        none;
    border:         none;
    background:     transparent;
    color:          mat.get-color-from-palette($red-primary, darker);
    cursor:         pointer;
    //pointer-events: auto;
    z-index:        10;
  }

}
