@use '@angular/material' as mat;
@import '../settings/index';
@import '../tools/index';


$_base-class: c-toggle-button;

.c-toggle-button {
  @include font-size($global-delta-size);

  padding:       ($global-baseline * 0.5) $global-spacing-unit-small;
  margin-bottom: $global-spacing-unit-tiny;
  border:        none;
  border-radius: $global-baseline;
  outline:       none;
  font-family:   unquote($global-body-font);
  background:    transparent;
  color:         mat.get-color-from-palette($red-primary, default);
  cursor:        pointer;

  &:hover,
  &:active {
    background-color: mat.get-color-from-palette($red-primary, lighter, .21);
  }

  &--active {
    font-weight:      $bold;
    background-color: mat.get-color-from-palette($red-primary, lighter, .34);
  }


  &--doing {
    color: mat.get-color-from-palette($red-accent, default);

    &:hover,
    &:active {
      background-color: mat.get-color-from-palette($red-accent, lighter, .21);
    }
  }

  &--active.#{ $_base-class }--doing {
    font-weight:      $bold;
    background-color: mat.get-color-from-palette($red-accent, lighter, .34);
  }


  &--waiting {
    color: mat.get-color-from-palette($red-orange, default);

    &:hover,
    &:active {
      background-color: mat.get-color-from-palette($red-orange, lighter, .21);
    }
  }

  &--active.#{ $_base-class }--waiting {
    font-weight:      $bold;
    background-color: mat.get-color-from-palette($red-orange, lighter, .34);
  }


  &--analysis {
    color: mat.get-color-from-palette($red-purple, default);

    &:hover,
    &:active {
      background-color: mat.get-color-from-palette($red-purple, lighter, .21);
    }
  }

  &--active.#{ $_base-class }--analysis {
    font-weight:      $bold;
    background-color: mat.get-color-from-palette($red-purple, lighter, .34);
  }


  &--done {
    color: mat.get-color-from-palette($red-blue, default);

    &:hover,
    &:active {
      background-color: mat.get-color-from-palette($red-blue, lighter, .21);
    }
  }

  &--active.#{ $_base-class }--done {
    font-weight:      $bold;
    background-color: mat.get-color-from-palette($red-blue, lighter, .34);
  }
}
