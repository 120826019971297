@import '../settings/settings.colors';
@import '../tools/tools.color-property-by-theme';


@mixin red-alert-box($theme) {

  .c-alert-box {
    @include color-properties-by-theme($theme, (
      'background-color': lighter,
      'color':            darker
    ));

    a {
      @include color-properties-by-theme($theme, ('color': darker));

      text-decoration: underline;
    }
  }
}
