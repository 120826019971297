@use '@angular/material' as mat;

@import 'settings/index';
@import 'tools/index';


.c-abacus-grid {
  @include font-size($global-zeta-size);

  display:               grid;
  grid-template-columns: 2fr 4fr minmax($global-spacing-unit-small, 1fr);

  position:      relative;
  border-radius: $global-baseline;
  border-left:   1px solid $red-dark-dividers;
  border-top:    1px solid $red-dark-dividers;
  overflow:      hidden;
  z-index:       1;

  @media print {
    page-break-inside: avoid;
  }

  @include mq($from: tablet-portrait) {
    grid-template-columns: 4fr 7fr minmax(max-content, 1fr);
  }

  &--no-score {
    grid-template-columns: 2fr 4fr;

    @include mq($from: tablet-portrait) {
      grid-template-columns: 4fr 7fr;
    }
  }

  &__cell {
    position:      relative;
    display:       flex;
    align-items:   center;
    border-right:  1px solid $red-dark-dividers;
    border-bottom: 1px solid $red-dark-dividers;
    padding:       $global-spacing-unit-tiny $global-spacing-unit-small;

    @media print {
      padding: $global-spacing-unit-tiny * 0.5;
    }

    &:not(&--heading,  &--titles) {
      &:hover::before {
        opacity: 1;
        width: 100%;
      }

      &::before {
        content:  '';
        position: absolute;
        left:     50%;
        height:   100%;
        width:    0;
        opacity:  0;
        z-index:  -1;

        background-color: $red-light-secondary-bg;
        border-radius:    $global-spacing-unit-small;

        transform:  translate(-50%, 0);
        transition: opacity $global-transition       $global-easing,
                    width   ($global-transition * 2) $global-easing;
      }
    }

    &--titles,
    &--scores {
      font-weight: $bold;
    }

    &--subtitles {
      font-weight:  $regular;
      padding-left: $global-spacing-unit;
    }

    &--titles {
      justify-content: flex-start;
      padding-right:   $global-baseline;
      min-height:      ($global-baseline * 8);
      z-index: 2;
      position: sticky;
      left: 0px;
      background: #f5f5f5;
    }

    &--scores {
      text-align:      center;
      justify-content: center;
      padding:         $global-baseline;
      position: sticky;
      right: 0px;
      z-index: 2;
      background: #f5f5f5;
    }

    &--values {
      display:               grid;
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
      position:              relative;
      padding:               0;
    }

    .c-accordion-icon { cursor: pointer; }
  }

  &__bar {
    position:         absolute;
    top:              50%;
    left:             0;
    height:           $global-spacing-unit-small;
    background-color: $red-dark-primary-bg;

    transform: translateY(-50%);
  }

  &__value {
    @include font-size($global-delta-size);

    display:         flex;
    flex-grow:       1;
    flex-wrap:       nowrap;
    align-items:     center;
    justify-content: center;
    min-width:       ($global-baseline * 6);
    height:          100%;
    text-align:      center;

    @each $type, $color in $red-score-levels {

      &--#{ $type } {
        grid-row:         1;
        grid-column:      index(($red-score-levels), ($type $color));
        background-color: mat.get-color-from-palette($color, default, .38);
      }

      &--disabled.c-abacus-grid__value--#{ $type } {
        background-color: mat.get-color-from-palette($color, default, .12);
      }

      &--empty.c-abacus-grid__value--#{ $type } {
        background-color: mat.get-color-from-palette($color, default);
      }
    }
  }

  &__face {
    @include font-size($global-spacing-unit, 1);

    height: ($global-baseline * 8);

    @each $type, $hue in $red-score-levels {
      &--#{$type} {
        background-color: mat.get-color-from-palette($hue, default);
      }
    }

  }

}
