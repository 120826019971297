@use '@angular/material' as mat;

@import '@angular/material/theming';

@import '../settings/index';
@import '../tools/tools.color-property-by-theme';


@mixin red-buttons-theme($theme) {

  .c-button {
    transition: color            $global-transition $global-easing,
                background-color $global-transition $global-easing,
                border-color     $global-transition $global-easing,
                box-shadow       $global-transition $global-easing;

    &--solid {
      @include color-properties-by-theme($theme, (
        'background-color': default,
        'color':            default-contrast
      ));

      &:not([disabled]):hover {
        @include color-properties-by-theme($theme, 'background-color', darker);
      }
    }

    &--solid-reversed {
      border-color:     mat.get-color-from-palette($red-gray, 50);
      background-color: mat.get-color-from-palette($red-gray, 50);

      @include color-properties-by-theme($theme, (
        'color': default
      ));

      &:not([disabled]):hover {
        @include color-properties-by-theme($theme, 'color', darker);
      }
    }

    &--solid,
    &--solid-reversed {
      &.c-button[disabled] {
        border-color:     transparent;
        background-color: $red-dark-disabled-button;
        color:            $red-dark-disabled-text;
        cursor:           default;
      }
    }

    &--outline {
      background-color: transparent;
      border-style:     solid;
      border-width:     2px;
      border-color:     currentColor;
      color:            currentColor;

      @include color-properties-by-theme($theme, (
        'border-color': default,
        'color':        default
      ));

      &:not([disabled]):hover {
        @include color-properties-by-theme($theme, (
          'border-color': darker,
          'color':        darker
        ));
      }

      &.c-button[disabled] {
        color:        $red-dark-disabled-text;
        border-color: $red-dark-disabled-button;
        cursor:       default;

        .mat-ripple-element { display: none; }
      }
    }

    .c-button[disabled] .mat-ripple-element { display: none; }
  }

}
