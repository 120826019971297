@import '../settings/settings.colors';
@import '../tools/tools.color-property-by-theme';


@mixin _red-icon-theme-base($theme, $hue) {
  @include color-properties-by-theme($theme, 'color', $hue);

  svg {
    @include color-properties-by-theme($theme, 'fill', $hue);
  }
}

@mixin red-icon-theme($theme) {

  .u-icon {
    @include _red-icon-theme-base($theme, default);

    display: inline-block;

    &-lighter {
      @include _red-icon-theme-base($theme, lighter);
    }

    &-darker {
      @include _red-icon-theme-base($theme, darker);
    }

    &-light {
      color: $red-light-primary-text !important;
      svg { fill: $red-light-primary-text !important; }
    }

    &-dark {
      color: $red-dark-primary-text !important;
      svg { fill: $red-dark-primary-text !important; }
    }

    &-disabled {
      cursor: default !important;
      color:  $red-dark-disabled-text !important;
      svg { fill: $red-dark-disabled-text !important; }
    }
  }
}
