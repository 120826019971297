@use '@angular/material' as mat;

@use './settings.color-palettes' as palettes;

@import '@angular/material/theming';


// Brand palettes
$red-primary:                      mat.define-palette(palettes.$primary-palette, 500, 400, 900);
$red-accent:                       mat.define-palette(palettes.$accent-palette, 500, 300, 800);

$red-red:                          mat.define-palette(palettes.$red-palette, 500, 100, 800);
$red-orange:                       mat.define-palette(palettes.$orange-palette, 500, 100, 800);
$red-yellow:                       mat.define-palette(palettes.$yellow-palette, 500, 100, 800);
$red-green:                        mat.define-palette(palettes.$green-palette, 500, 100, 800);
$red-blue:                         mat.define-palette(palettes.$blue-palette, 500, 100, 800);
$red-gray:                         mat.define-palette(palettes.$gray-palette, 500, 100, 800);
$red-brown:                        mat.define-palette(palettes.$brown-palette, 500, 100, 800);
$red-pink:                         mat.define-palette(palettes.$pink-palette, 500, 100, 800);
$red-purple:                       mat.define-palette(palettes.$purple-palette, 500, 100, 800);

// Background
$red-light-primary-bg:             mat.get-color-from-palette(palettes.$gray-palette, 50) !default;
$red-light-secondary-bg:           mat.get-color-from-palette(palettes.$gray-palette, 100) !default;
$red-light-disabled-button:        mat.get-color-from-palette(palettes.$gray-palette, 50, 0.12);
$red-dark-primary-bg:              mat.get-color-from-palette(palettes.$gray-palette, 800) !default;
$red-dark-secondary-bg:            mat.get-color-from-palette(palettes.$gray-palette, 500) !default;
$red-dark-disabled-button:         mat.get-color-from-palette(palettes.$gray-palette, 900, 0.12);

// Foreground
$red-dark-primary-text:            mat.get-color-from-palette(palettes.$gray-palette, 900, 0.87) !default;
$red-dark-secondary-text:          mat.get-color-from-palette(palettes.$gray-palette, 900, 0.64) !default;
$red-dark-disabled-text:           mat.get-color-from-palette(palettes.$gray-palette, 900, 0.38) !default;
$red-dark-dividers:                mat.get-color-from-palette(palettes.$gray-palette, 900, 0.12) !default;
$red-dark-focused:                 mat.get-color-from-palette(palettes.$gray-palette, 900, 0.12) !default;
$red-light-primary-text:           mat.get-color-from-palette(palettes.$gray-palette, 50) !default;
$red-light-secondary-text:         mat.get-color-from-palette(palettes.$gray-palette, 50, 0.7) !default;
$red-light-disabled-text:          mat.get-color-from-palette(palettes.$gray-palette, 50, 0.5) !default;
$red-light-dividers:               mat.get-color-from-palette(palettes.$gray-palette, 50, 0.12) !default;
$red-light-focused:                mat.get-color-from-palette(palettes.$gray-palette, 50, 0.12) !default;

// link colors
$red-base-link-color:              mat.get-color-from-palette($red-accent, default);
$red-hover-link-color:             mat.get-color-from-palette($red-accent, darker);
$red-active-link-color:            mat.get-color-from-palette($red-accent, lighter);


// Alert level palettes
$red-danger:                       mat.define-palette(palettes.$red-palette, 600, 100, 900);
$red-warn:                         mat.define-palette(palettes.$orange-palette, 500, 100, 900);
$red-attention:                    mat.define-palette(palettes.$yellow-palette, 500, 100, 900);
$red-success:                      mat.define-palette(palettes.$green-palette, 500, 100, 900);
$red-info:                         mat.define-palette(palettes.$blue-palette, 500, 100, 900);

$red-alert-levels:                 (
                                     'danger': $red-danger,
                                     'warn': $red-warn,
                                     'attention': $red-attention,
                                     'success': $red-success,
                                     'info': $red-info
                                   );

$red-alert-levels-theme:           (
                                     namespace: 'alert',
                                     palettes: $red-alert-levels
                                   );

// Score level palettes
$red-worst:                        mat.define-palette(palettes.$gray-palette, 500, 300, 700);
$red-bad:                          mat.define-palette(palettes.$red-palette, 500, 300, 700);
$red-poor:                         mat.define-palette(palettes.$orange-palette, 500, 300, 800);
$red-average:                      mat.define-palette(palettes.$yellow-palette, 600, 300, 700);
$red-good:                         mat.define-palette(palettes.$green-palette, 500, 300, 700);
$red-best:                         mat.define-palette(palettes.$accent-palette, 500, 300, 700);

$red-score-levels:                 (
                                     'pessimo': $red-worst,
                                     'precario': $red-bad,
                                     'razoavel': $red-poor,
                                     'mediano': $red-average,
                                     'bom': $red-good,
                                     'otimo': $red-best
                                   );

                                  //  @for $i from 1 through 5 {
                                  //   $red-score-levels: map-merge($red-score-levels, ('otimo#{$i}': $red-best));
                                  // }


$red-score-levels-namespace:       'score-level';
$red-score-levels-theme:           (
                                     namespace: $red-score-levels-namespace,
                                     palettes: $red-score-levels
                                   );

// Proofreading mark palettes
$red-axis-1-mark:                  mat.define-palette(palettes.$pink-palette, 500, 100, 900);
$red-axis-2-mark:                  mat.define-palette(palettes.$orange-palette, 500, 100, 900);
$red-axis-3-mark:                  mat.define-palette(palettes.$purple-palette, 500, 100, 900);
$red-axis-4-mark:                  mat.define-palette(palettes.$blue-palette, 500, 100, 900);
$red-axis-5-mark:                  mat.define-palette(palettes.$green-palette, 500, 100, 900);
$red-axis-6-mark:                  mat.define-palette(palettes.$brown-palette, 500, 100, 900);
$red-custom-mark:                  mat.define-palette(palettes.$gray-palette, 700, 300, 900);

$red-marks:                        (
                                     'grupo-c1': $red-axis-1-mark,
                                     'grupo-c2': $red-axis-2-mark,
                                     'grupo-c3': $red-axis-3-mark,
                                     'grupo-c4': $red-axis-4-mark,
                                     'grupo-c5': $red-axis-5-mark,
                                     'grupo-c6': $red-axis-6-mark,
                                     'grupo-outros': $red-custom-mark
                                   );

$red-marks-namespace:              'mark';
$red-marks-theme:                  (
                                     namespace: $red-marks-namespace,
                                     palettes: $red-marks
                                   );

// Categories palettes
$red-category-anglo:               mat.define-palette(mat.$blue-palette, 500, 300, 800);
$red-category-enem:                mat.define-palette(palettes.$green-palette, 500, 100, 900);
$red-category-enem-basico:         mat.define-palette(palettes.$green-palette, 500, 100, 900);
$red-category-enem-descomplica:    mat.define-palette(palettes.$green-palette, 300, 50, 700);
$red-category-fuvest:              mat.define-palette(palettes.$blue-palette, 500, 100, 900);
$red-category-fuvest-descomplica:  mat.define-palette(palettes.$blue-palette, 300, 50, 700);
$red-category-unicamp:             mat.define-palette(mat.$blue-gray-palette, 600, 300, 700);
$red-category-unicamp-descomplica: mat.define-palette(mat.$blue-gray-palette, 500, 300, 600);
$red-category-fgv:                 mat.define-palette(palettes.$brown-palette, 500, 100, 900);
$red-category-universal:           mat.define-palette(palettes.$orange-palette, 500, 300, 700);
$red-category-fundamental:         mat.define-palette(palettes.$pink-palette, 500, 300, 700);
$red-category-ensino-médio:        mat.define-palette(palettes.$purple-palette, 500, 300, 700);
$red-category-insper:              mat.define-palette(palettes.$red-palette, 500, 300, 700);
$red-category-pas:                 mat.define-palette(mat.$green-palette, 600, 300, 700);
$red-category-ufsc:                mat.define-palette(mat.$indigo-palette, 500, 300, 600);
$red-category-unesp:               mat.define-palette(mat.$teal-palette, 500, 300, 700);
$red-category-vunesp:              mat.define-palette(palettes.$red-palette, 600, 400, 900);

$red-categories:                   (
                                     'redes': $red-purple,
                                     'escolas': $red-accent,
                                     'escolas-rede': $red-accent,
                                     'turmas': $red-primary,
                                     'alunos': $red-blue,
                                     'anglo': $red-category-anglo,
                                     'enem': $red-category-enem,
                                     'enem-descomplica': $red-category-enem-descomplica,
                                     'enem-basico': $red-category-enem-basico,
                                     'simulado': $red-category-enem-basico,
                                     'fuvest': $red-category-fuvest,
                                     'fuvest-descomplica': $red-category-fuvest-descomplica,
                                     'unicamp': $red-category-unicamp,
                                     'unicamp-descomplica': $red-category-unicamp-descomplica,
                                     'fgv': $red-category-fgv,
                                     'universal': $red-category-universal,
                                     'fundamental': $red-category-fundamental,
                                     'ensino-medio': $red-category-ensino-médio,
                                     'insper': $red-category-insper,
                                     'pas': $red-category-pas,
                                     'ufsc': $red-category-ufsc,
                                     'unesp': $red-category-unesp,
                                     'vunesp': $red-category-vunesp,
                                   );


// table colors palettes aliases
$red-table-cells:                  (
                                     0: $red-orange,
                                     1: $red-pink,
                                     2: $red-purple,
                                     3: $red-blue,
                                     4: $red-green,
                                     5: $red-brown,
                                     'neutral': $red-gray,
                                   );
