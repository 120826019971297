@import '@angular/material/theming';

@import '../settings/settings.colors';
@import '../tools/tools.color-property-by-theme';


@mixin red-title-qualifier($theme) {

  .u-title-qualifier {
    @include color-properties-by-theme($theme, 'color', default);

    .t-neutral &,
    &.t-neutral.t-neutral {
      color: $red-dark-secondary-text;
    }
  }

}
