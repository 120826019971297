@use '@angular/material' as mat;

@import '@angular/material/theming';

@import '../settings/index';
@import '../tools/tools.color-property-by-theme';


@mixin red-toolbar-theme($theme) {
  .c-toolbar {
    @include color-properties-by-theme($theme, (
      'background-color': darker,
      'color':            lighter
    ));

    background-color: mat.get-color-from-palette($red-primary, default);
    color:            $red-light-primary-text;

    a {
      color:           currentColor;
      text-decoration: none;
    }
  }
}
