@use '@angular/material' as mat;

@import 'settings/index';
@import 'tools/index';


.c-grid-table {
  @include font-size($global-epsilon-size);

  position:          relative;
  display:           grid;
  grid-gap:          0;
  grid-auto-columns: minmax(min-content, auto);
  margin-bottom:     $global-spacing-unit;
  border:            1px solid $red-dark-dividers;
  border-right:      none;
  border-radius:     $global-baseline;
  overflow-x:        auto;
  overflow-y:        hidden;

  &::-webkit-scrollbar {
    width:  $global-baseline;
    height: $global-baseline;
  }

  &::-webkit-scrollbar-track {
    background-color: $red-light-secondary-bg;
  }

  &::-webkit-scrollbar-thumb {
    background-color: mat.get-color-from-palette($red-accent, default);
  }

  &__title,
  &__cell,
  &__sub {
    position:          relative;
    display:           flex;
    align-items:       flex-start;
    padding:           $global-spacing-unit-tiny;
    border-block-end:  1px solid $red-dark-dividers;
    border-inline-end: 1px solid $red-dark-dividers;
    z-index:           0;

    &--number { justify-content: flex-end; }
    &--edit   { justify-content: flex-end; }
    &--center { justify-content: center; text-align: center; }

    &--sticky {
      position: sticky;
      z-index:  1;
    }

    &--positive {
      background-color: mat.get-color-from-palette($red-blue, default);

      $_keys: map-keys($red-blue);

      @for $i from 1 through 9 {
        $_key: nth($_keys, $i + 1);

        @if type-of(mat.get-color-from-palette($red-blue, $_key)) == color {
          &-#{$i} {
            color:            mat.get-color-from-palette($red-blue, #{ $_key }-contrast) !important;
            background-color: mat.get-color-from-palette($red-blue, $_key) !important;
          }
        }
      }
    }

    &--negative {
      background-color: mat.get-color-from-palette($red-red, default);

      $_keys: map-keys($red-red);

      @for $i from 1 through 9 {
        $_key: nth($_keys, $i + 1);

        @if type-of(mat.get-color-from-palette($red-red, $_key)) == color {
          &-#{$i} {
            color:            mat.get-color-from-palette($red-red, #{ $_key }-contrast) !important;
            background-color: mat.get-color-from-palette($red-red, $_key) !important;
          }
        }
      }
    }
  }

  &__status { margin-top: 20px; }

  &__title {
    grid-row:         1;
    font-weight:      $bold;
    background-color: $red-light-primary-bg;
  }

  &__cell,
  &__title {
    background-color: $red-light-primary-bg;

    &--even {
      &::before {
        content:          '';
        position:         absolute;
        top:              0;
        right:            0;
        bottom:           0;
        left:             0;
        background-color: mat.get-color-from-palette($red-primary, default, .075);
        pointer-events:   none;
      }
    }

    &--highlight {
      background-color: mat.get-color-from-palette($red-primary, 100);
      font-weight:      $bold;
    }

    &--hover-highlight {
      &::after {
        content:          '';
        position:         absolute;
        top:              0;
        right:            0;
        bottom:           0;
        left:             0;
        background-color: mat.get-color-from-palette($red-primary, default, .16);
        pointer-events:   none;
      }
    }
  }

  &__title {
    &--color {
      @each $key, $value in $red-table-cells {
        &-#{ $key } {
          background-color: mat.get-color-from-palette($value, 100);
        }
      }

      &-primary {
        background-color: mat.get-color-from-palette($red-primary, default);
        color:            mat.get-color-from-palette($red-primary, default-contrast);
      }

      &-accent {
        background-color: mat.get-color-from-palette($red-accent, default);
        color:            mat.get-color-from-palette($red-accent, default-contrast);
      }

      &-warn {
        background-color: mat.get-color-from-palette($red-warn, default);
        color:            mat.get-color-from-palette($red-warn, default-contrast);
      }
    }
  }

  &__cell {
    &--color {
      @each $key, $value in $red-table-cells {
        &-#{ $key } { background-color: mat.get-color-from-palette($value, 50); }
      }

      &-primary {
        background-color: mat.get-color-from-palette($red-primary, lighter);
        color:            mat.get-color-from-palette($red-primary, lighter-contrast);
      }

      &-accent {
        background-color: mat.get-color-from-palette($red-accent, lighter);
        color:            mat.get-color-from-palette($red-accent, lighter-contrast);
      }

      &-warn {
        background-color: mat.get-color-from-palette($red-warn, lighter);
        color:            mat.get-color-from-palette($red-warn, lighter-contrast);
      }
    }
  }

  &__subgrid {
    @include flush($global-spacing-unit-tiny, true);

    display:           grid;
    grid-auto-flow:    column;
    grid-auto-columns: 1fr;
    height:            calc(100% + #{ $global-spacing-unit-small });
    border:            0;
    border-radius:     0;

    &-cell {
      background-color: transparent;

      &:last-child { border-inline-end: 0; }
    }
  }
}
