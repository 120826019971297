@use '@angular/material' as mat;
@import 'settings/index';
@import 'tools/index';


.c-selection-box {
  display:               grid;
  grid-column-gap:       $global-spacing-unit;
  //grid-template-columns: repeat(auto-fill, minmax($global-baseline * 60, 1fr));
  width:                 100%;
  padding-block-end:     $global-spacing-unit;

  &__options {
    overflow:       auto;
    max-height:     ($global-baseline * 60);
    text-transform: uppercase;

    .mat-list-option {
      @include font-size($global-delta-size);

      font-family: unquote($global-body-font);
    }


    &::-webkit-scrollbar {
      width:  $global-baseline;
      height: $global-baseline;
    }

    &::-webkit-scrollbar-track {
      background-color: $red-light-secondary-bg;
    }

    &::-webkit-scrollbar-thumb {
      background-color: mat.get-color-from-palette($red-accent, default);
    }
  }

  &__option {
    position: relative;
    &--hidden-check-box{
      height: auto!important;
      min-height: 42px;
      display: flex!important;
      align-items: center;
      .mat-list-item-content{
        .mat-pseudo-checkbox{
          display: none!important;
        }
      }
    }

    &--even {
      &::before {
        content:          '';
        position:         absolute;
        top:              0;
        right:            0;
        bottom:           0;
        left:             0;
        background-color: mat.get-color-from-palette($red-primary, default, .075);
        pointer-events:   none;
      }
    }
  }

  &__summary-title {
    display:        inline-flex;
    gap:            6px;
    align-items:    center;
    text-transform: uppercase;
  }

  &__button {
    display:          block;
    margin-block-end: $global-spacing-unit-small;
  }
}
