@import 'settings.aliases';

// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.


// Standardise some UI treatments.
$global-radius:     ($global-baseline * 0.5);
$global-transition: .3s;
$global-easing:     cubic-bezier(.25, .8, .25, 1);





// flexgrid object

$inuit-flexgrid-spacing-sizes: (
  null:      $global-spacing-unit,
  '--tiny':  $global-spacing-unit-tiny,
  '--flush': 0,
);





// Typography
// ========================================================================== */

$global-body-font:     '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif';
$global-headings-font: 'Raleway, "Helvetica Neue", Helvetica, Arial, sans-serif';


// heading font sizes
$global-omega-size:   60px;
$global-alpha-size:   32px;
$global-beta-size:    24px;
$global-gamma-size:   20px;
$global-delta-size:   16px;
$global-epsilon-size: 14px;
$global-zeta-size:    12px;


// font-weight
$regular: 400;
$bold:    700;
$black:   900;





// Measurements
// ========================================================================== */

$global-max-width:        1600px !default;
$abacus-drawer-max-width: ($global-baseline * 75) !default;

$toolbar-size: (
  'phone':            $global-baseline * 8,
  'tablet-portrait':  $global-baseline * 10,
  'tablet-landscape': $global-baseline * 20,
  'desktop':          $global-baseline * 24,
) !default;

$toolbar-institutional-height: (
  'phone':            $global-baseline * 10,
  'tablet-landscape': $global-baseline * 15
) !default;





// aspect ratios - predefines inuitcss` defaults
// ========================================================================== */

$inuit-ratios: (
  'hd'     : (16:9),
  'square' : (1:1),
  'a4'     : (595:842)
) !default;

$inuit-crops: $inuit-ratios !default;





// Other pre-definitions
// ========================================================================== */

$inuit-list-inline-delimiter: '&';
