@import '../settings/index';


/* ==========================================================================
   #MEDIA
   ========================================================================== */

/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 *
 * This is a different implementation, using flexbox (by @herzinger)
 */

 .o-media {
   display:        flex;
   flex-direction: row;
 }

  .o-media__img {
    flex-grow:    0;
    flex-shrink:  0;
    margin-right: $global-spacing-unit;

    > img { display: block; }
  }

  .o-media__body {
    flex-grow:  1;
    overflow:   hidden;
    display:    block;

    &,
    > :last-child { margin-bottom: 0; }
  }





/* Size variants
   ========================================================================== */

/**
 * Modify the amount of space between our image and our text. We also have
 * reversible options for all available sizes.
 */

.o-media--flush {

  > .o-media__img {
    margin-right: 0;
    margin-left:  0;
 }

}


.o-media--tiny {

  > .o-media__img { margin-right: $global-spacing-unit-tiny; }

}


.o-media--small {

  > .o-media__img { margin-right: $global-spacing-unit-small; }

}


.o-media--large {

  > .o-media__img { margin-right: $global-spacing-unit-large; }

}


.o-media--huge {

  > .o-media__img { margin-right: $global-spacing-unit-huge; }

}





/* Reversed media objects
   ========================================================================== */

.o-media--reverse {
  flex-direction: row-reverse;

  > .o-media__img {
    float:        right;
    margin-right: 0;
    margin-left:  $global-spacing-unit;
  }

  &.o-media--tiny {

    > .o-media__img {
      margin-right: 0;
      margin-left:  $global-spacing-unit-tiny;
    }

  }

  &.o-media--small {

    > .o-media__img {
      margin-right: 0;
      margin-left:  $global-spacing-unit-small;
    }

  }

  &.o-media--large {

    > .o-media__img {
      margin-right: 0;
      margin-left:  $global-spacing-unit-large;
    }

  }

  &.o-media--huge {

    > .o-media__img {
      margin-right: 0;
      margin-left:  $global-spacing-unit-huge;
    }

  }

}





/* Vertical alignment variants
   ========================================================================== */

.o-media--start,
.o-media--end,
.o-media--middle {

  > .o-media__img { display: flex; }
}


.o-media--start   { align-items: flex-start; }
.o-media--end     { align-items: flex-end; }
.o-media--middle  { align-items: center; }
