@import '@angular/material/theming';

@import '../settings/settings.colors';
@import '../tools/tools.color-property-by-theme';


@mixin red-dashboard-content-theme($theme) {

  .c-dashboard-content {
    @include color-properties-by-theme($theme, 'border-color', default);

    .t-neutral &,
    &.t-neutral.t-neutral {
      border-color: $red-dark-disabled-text;
    }
  }
}
