@import '~sass-mq/mq';
@import '../settings/index';


/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  padding-right:  $global-spacing-unit-small;
  padding-left:   $global-spacing-unit-small;
  margin-right:   auto;
  margin-left:    auto;
  width:          100%;
  max-width:      $global-max-width;

  @include mq($from: desktop) {
    padding-right: $global-spacing-unit;
    padding-left:  $global-spacing-unit;
  }
}
