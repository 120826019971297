/* ==========================================================================
   INUITCSS
   ========================================================================== */

/**
 * inuitcss, by @csswizardry
 *
 * github.com/inuitcss | inuitcss.com
 */


/**
 * CONTENTS
 *
 * SETTINGS
 * Config...............Project-level configuration and feature switches.
 * Core.................inuitcss’ core and setup settings.
 * Global...............Project-wide variables and settings.
 *
 * TOOLS
 * Font-size............A mixin which guarantees baseline-friendly line-heights.
 * Clearfix.............Micro clearfix mixin.
 * Hidden...............Mixin for hiding elements.
 * Strip-Unit...........Function for stripping unit from numbers.
 * Icon-Size............Mixin scaling icons.
 * Z-Depth..............Mixin adding Material Design Shadows.
 * Sass MQ..............inuitcss’ default media query manager.
 *
 * GENERIC
 * Box-sizing...........Better default `box-sizing`.
 * Normalize.css........A level playing field using @necolas’ Normalize.css.
 * Reset................A tiny reset to complement Normalize.css.
 * Shared...............Sensibly and tersely share some global commonalities
 *                      (particularly useful when managing vertical rhythm).
 *
 * ELEMENTS
 * Page.................Set up our document’s default `font-size` and
 *                      `line-height`.
 * Headings.............Very minimal (i.e. only font-size information) for
 *                      headings 1 through 6.
 * Images...............Base image styles.
 * Tables...............Simple table styles.
 *
 * OBJECTS
 * Wrapper..............Page constraint object.
 * Layout...............Generic layout module.
 * Flexgrid.............Generic layout module using flexbox.
 * Media................Image- and text-like content side by side. The
 *                      poster-child of OOCSS.
 * Flag.................Table-layout-based advancement on the Media object.
 * List-bare............Lists with no bullets or indents.
 * List-inline..........A list whose items all site in a line.
 * Box..................Simple boxing abstraction.
 * Block................Image-on-top-of-text object.
 * Ratio................A container for maintaining aspect ratio of content.
 * Crop.................Provide a cropping context for media (images, etc.).
 * Table................Classes for manipulating `table`s.
 * Pack.................Pack items into available horizontal space.
 *
 * COMPONENTS
 * Buttons..............An example button component, and how it fits into the
 *                      inuitcss framework.
 *
 * UTILITIES
 * Clearfix.............Bind our clearfix onto a utility class.
 * Widths...............Simple width helper classes.
 * Headings.............Reassigning our heading styles to helper classes.
 * Spacings.............Nudge bits of the DOM around with these spacing
 *                      classes.
 * Responsive-Spacings..Enhances the function of normal spacings for
 *                      responsive usage.
 * Print................Reset-like styles taken from the HTML5 Boilerplate.
 * Hide.................Helper classes to hide content
 */

// SETTINGS
@import 'styles/settings/index';






// TOOLS
@import 'inuitcss/tools/tools.hidden';
@import 'styles/tools/index';





// GENERIC
@import 'inuitcss/generic/generic.box-sizing';
@import 'inuitcss/generic/generic.normalize';
@import 'inuitcss/generic/generic.reset';
@import 'inuitcss/generic/generic.shared';
@import 'styles/generic/generic.hacks';
@import 'styles/generic/generics.from-server';





// ELEMENTS
@import 'styles/elements/elements.page';
@import 'inuitcss/elements/elements.images';
@import 'inuitcss/elements/elements.tables';
@import 'styles/elements/elements.type';




// OBJECTS
@import 'styles/objects/objects.avatar';
@import 'styles/objects/objects.backdrop';
@import 'inuitcss/objects/objects.crop';
@import 'styles/objects/objects.data-list';
@import 'styles/objects/objects.form';
@import 'inuit-flexgrid/objects/objects.grid';
@import 'inuitcss/objects/objects.list-bare';
@import 'inuitcss/objects/objects.list-inline';
@import 'styles/objects/objects.list-ordered';
@import 'styles/objects/objects.media';
@import 'inuitcss/objects/objects.ratio';
@import 'styles/objects/objects.table';
@import 'styles/objects/objects.wrapper';





// COMPONENTS
@import 'styles/components/components.abacus-grid';
@import 'styles/components/components.accordion';
@import 'styles/components/components.button';
@import 'styles/components/components.card';
@import 'styles/components/components.collapsible';
@import 'styles/components/components.cover';
@import 'styles/components/components.dashboard-content';
@import 'styles/components/components.dropdown-menu';
@import 'styles/components/components.edit-user';
@import 'styles/components/components.editor';
@import 'styles/components/components.grid-table';
@import 'styles/components/components.red-table';
@import 'styles/components/components.home-section';
@import 'styles/components/components.hub-grid';
@import 'styles/components/components.loading';
@import 'styles/components/components.mark';
@import 'styles/components/components.marketing-dashboard';
@import 'styles/components/components.modal';
@import 'styles/components/components.pill';
@import 'styles/components/components.queue';
@import 'styles/components/components.selection-box';
@import 'styles/components/components.toggle-button';
@import 'styles/components/components.tools';

.c-tooltip { @include font-size($global-epsilon-size, $modifier: -1); }
.c-overlay{width:auto!important;}





// THEMES
@import 'styles/themes/index';





// UTILITIES
@import 'inuitcss/utilities/utilities.widths';
@import 'inuitcss/utilities/utilities.spacings';
@import 'inuitcss/utilities/utilities.hide';
@import 'styles/utilities/utilities.print';
@import 'styles/utilities/utilities.sticky';
@import 'styles/utilities/utilities.unstyle';
@import 'styles/utilities/utilities.z-depth';
@import 'styles/utilities/utilities.trim';
@import 'styles/utilities/utilities.icon';
@import 'styles/utilities/utilities.wrapping';
