@use '@angular/material' as mat;

@use 'sass:map';

@import 'sass-mq/mq';

@import '../settings/index';
@import '../tools/tools.z-depth';
@import '../tools/tools.font-size';
@import '../tools/tools.responsive-property-from-map';


// [1] decorative round border on top of the element
// [2] make sure to realign to the baseline

$_border-top-height: $global-baseline; // [1]

.c-dashboard-content {
  @include z-depth(3);
  @include font-size($global-gamma-size);

  position:         relative;
  display:          flex;
  flex-direction:   column;
  background-color: $red-light-primary-bg;
  padding:          $global-spacing-unit-small;

  @include mq($from: tablet-portrait) {
    display:               grid;
    grid-column-gap:       $global-spacing-unit;
    grid-template-columns: 100%;
  }

  @include mq($from: tablet-landscape) {
    padding: $global-spacing-unit;
  }

  @include mq($from: desktop) {
    grid-template-columns: calc(75ch + 1.5em) minmax(0, 1fr);
  }

  &__header { grid-column: 1 / -1; }

  @include mq($from: stable) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap:       $global-spacing-unit-large;
  }

  @media print {
    display:    block !important;
    padding:    0;
    border:     none;
    box-shadow: none;
  }

  &--full-width { grid-template-columns: minmax(0, 1fr); }

  &--full-width &__header { grid-column: 1; }

  &--full-width &__main { margin-bottom: $global-spacing-unit-large; }

  &--full-width &__textarea { @include font-size($global-alpha-size); }

  &::before {
    position:         absolute;
    top:              $_border-top-height * -1;
    left:             0;
    right:            0;
    display:          block;
    content:          '';
    height:           $_border-top-height;
    border-radius:    $_border-top-height $_border-top-height 0 0;
    margin-top:       $global-baseline - $_border-top-height; // [2]

    background-color: mat.get-color-from-palette($red-accent, default);
    background-image: linear-gradient(
                          90deg,
                          mat.get-color-from-palette($red-primary, darker) 0%,
                          mat.get-color-from-palette($red-accent, default) 50%,
                          mat.get-color-from-palette($red-primary, darker) 100%
                      );
  }

  .bare &.c-edit-essay {
    padding-block-end: ($global-baseline * 22);

    @include mq($from: tablet-portrait) {
      padding-block-end: ($global-baseline * 28);
    }

    @include mq($from: tablet-landscape) {
      padding-block-end: ($global-baseline * 35);
    }

    @include mq($from: desktop) {
      padding-block-end: $global-spacing-unit;
    }
  }

  &__header {
    display:          flex;
    background-color: $red-light-primary-bg;
    border-bottom:    1px solid $red-dark-dividers;
    margin-bottom:    $global-spacing-unit;
    padding-bottom:   $global-spacing-unit-small;
    z-index:          9;

    .mat-form-field-appearance-outline .mat-form-field-wrapper {
      margin:  0;
      padding: 0;
    }

    @include mq($from: tablet-landscape) {
      position:    sticky;
      top:         0;
      margin-top:  $global-spacing-unit-small * -1;
      padding-top: $global-spacing-unit-small;
    }

    @media print {
      position: static !important;
      top:      0 !important;
      border:   none;
    }

    .bare & { top: 0 !important; }

    &-form {
      @include font-size($global-delta-size);

      margin-left: auto;
      margin-top:  $global-spacing-unit-tiny;
    }

    &-pill {
      @include font-size($global-epsilon-size, 1em);

      position:         relative;
      top:              ($global-baseline * 0.5) * -1;
      display:          inline-block;
      padding:          ($global-baseline * 0.5) $global-baseline;
      margin-inline:    $global-spacing-unit-small;
      width:            fit-content;
      border:           1px solid currentColor;
      border-radius:    $global-baseline;
      font-family:      unquote($global-body-font);
      text-transform:   uppercase;
      font-weight:      $bold;
      background-color: mat.get-color-from-palette($red-accent, default-contrast);
      color:            mat.get-color-from-palette($red-accent, default);
    }
  }

  &__filter {
    width: 100%;

    @include mq($from: tablet-landscape) {
      width: auto;
    }

    &-control {
      @include font-size($global-delta-size);

      width:         100%;
      margin-bottom: $global-spacing-unit;

      &:last-child { margin-bottom: 0; }

      @include mq($from: tablet-landscape) {
        width:         auto;
        margin-bottom: 0;
        margin-right:  $global-spacing-unit-small;

        &:last-child { margin-right: 0; }
      }
    }
  }

  &__title {
    margin-bottom: $global-baseline;
    text-align:    center;

    @include mq($from: tablet-landscape) {
      text-align: start;
    }

  }

  &__title-tab {
    display:      inline-block;
    margin-right: $global-spacing-unit;
    cursor:       default;

    &:hover {
      color:           inherit;
      text-decoration: none;
    }

    &:last-child { margin-right: 0; }

    @media print {
      text-decoration: none;
      &::after { content: none; display: none; }
    }
  }

  &--rewrite &__title-tab {
    cursor: pointer;
  }

  &__main { @include font-size($global-delta-size); }

  @media print {
    &__main { @include font-size($global-epsilon-size); }
  }

  &__textarea {
    @include font-size($global-gamma-size);

    width:         100%;
    margin-bottom: $global-spacing-unit-small;

    @include mq($from: desktop) {
      margin-bottom: 0;
    }

    .mat-form-field-wrapper {
      margin-bottom: 0;
      //padding-bottom: 0;
    }

    @media print { display: none !important; }
  }

  &__image-editor {
    @media print { display: none !important; }
  }

  &__error {
    text-align: center;
    margin:     $global-spacing-unit-large auto;
  }

  &__summary {
    padding-right: $global-spacing-unit;
  }

  &__actions {
    width:     100%;
    max-width: ($global-spacing-unit * 16);
    margin:    0 auto $global-spacing-unit;

    @include mq($from: tablet-landscape) {
      width:        auto;
      margin-right: 0;
    }
  }
}


.c-legacy-menu {
  @include font-size($global-zeta-size);

  display:         flex;
  flex:            0 0 auto;
  flex-wrap:       wrap;
  justify-content: center;
  max-width:       100%;
  text-transform:  uppercase;
  font-weight:     $bold;

  @include mq($from: tablet-portrait) {
    flex-direction: column;
  }

  &__item {
    display:       block;
    width:         fit-content;
    margin-bottom: $global-spacing-unit-small;
    margin-right:  $global-spacing-unit-tiny;
    margin-left:   $global-spacing-unit-tiny;

    @include mq($from: tablet-portrait) {
      margin-right: 0;
      margin-left:  0;
    }

    &:hover { text-decoration: none; }
  }
}
