@use '@angular/material' as mat;
@import '../settings/index';
@import '../tools/index';


.c-tools {
  grid-column:           1 / -1;
  display:               grid;
  grid-gap:              $global-spacing-unit;
  grid-template-columns: 1fr max-content;
  justify-content:       flex-end;
  align-items:           center;

  &__section:not(:last-child) {
    display:         flex;
    justify-content: flex-end;
    align-items:     center;
    padding-right:   $global-spacing-unit;
    border-right:    1px solid $red-dark-dividers;
  }

  &__start-section {
    grid-column: 1 / -1;

    @include mq($from: tablet-landscape) {
      grid-column: auto;
      grid-row:    auto;
    }
  }

  &__button {
    border-radius: 0;

    &:first-of-type { border-bottom-left-radius: $global-baseline;  border-top-left-radius: $global-baseline; }
    &:last-of-type  { border-bottom-right-radius: $global-baseline; border-top-right-radius: $global-baseline; }
  }
}
