@import '../settings/settings.colors';
@import '../tools/tools.color-property-by-theme';


@mixin _red-pill-theme-base($theme, $hue) {
  @include color-properties-by-theme($theme, (
    'border-color':     $hue,
    'background-color': $hue,
    'color':            $hue + '-contrast'
  ));
}

@mixin _red-pill-theme-negative($theme, $hue) {
  @include color-properties-by-theme($theme, (
    'border-color':     $hue + '-contrast',
    'background-color': $hue + '-contrast',
    'color':            $hue
  ));
}

@mixin red-pill-theme($theme) {

  $_block: '.c-pill';

  .c-pill {
    @include _red-pill-theme-base($theme, default);

    &--lighter {
      @include _red-pill-theme-base($theme, lighter);
    }

    &--darker {
      @include _red-pill-theme-base($theme, darker);
    }


    // negative

    &--negative {
      @include _red-pill-theme-negative($theme, default);
    }

    &--negative#{$_block}--lighter {
      @include _red-pill-theme-negative($theme, lighter);
    }

    &--negative#{$_block}--darker {
      @include _red-pill-theme-negative($theme, darker);
    }


    // disabled

    &--disabled {
      color:            $red-dark-disabled-text !important;
      background-color: $red-dark-disabled-button !important;
      border-color:     transparent !important;
    }
  }
}
