@import '../tools/index';
@import '../settings/index';


.o-form {
  @include font-size($global-delta-size);

  &__control {
    width: 100%;

    &--boolean {
      display:         flex;
      padding-bottom:  1.34375em;
      margin:          .25em 0;
      flex-direction:  column;
      align-items:     flex-start;
      justify-content: center;
    }
  }

  &__boolean-indent {
    padding: $global-spacing-unit-small 0 0 ($global-baseline * 7);
  }

  &__suffix {
    @include font-size($global-epsilon-size);

    position:         relative;
    top:              -$global-spacing-unit-tiny;
    display:          flex;
    align-items:      center;
    justify-content:  center;
    width:            $global-spacing-unit;
    height:           $global-spacing-unit;
    margin-left:      $global-spacing-unit-small;
    padding:          $global-spacing-unit-tiny;
    border:           none;
    border-radius:    100%;
    background-color: $red-dark-dividers;
    color:            $red-dark-disabled-text;
    cursor:           pointer;

    &:hover {
      color: $red-dark-primary-text;
    }
  }

  &__controls {
    grid-column: 1 / -1;
    text-align:  center;

    @include mq($from: tablet-landscape) {
      text-align: end;
    }
  }
}
