@import 'settings/index';
@import 'tools/index';


.c-collapsible {
  visibility:     collapse;
  overflow:       hidden;
  min-height:     unset;
  max-height:     0;
  padding-top:    0;
  padding-bottom: 0;
  border-width:   0;

  &--open {
    visibility:     visible;
    overflow:       visible;
    max-height:     ($global-baseline * 15);
    padding-top:    $global-spacing-unit-tiny;
    padding-bottom: $global-spacing-unit-tiny;
    border-width:   1px;

    transition: visibility   $global-transition $global-easing,
                max-height   $global-transition $global-easing,
                padding      $global-transition $global-easing,
                border-width $global-transition $global-easing;

    &.c-abacus-grid__cell--values { padding: 0; }
  }
}
