@import '@angular/material/theming';

@import '../settings/index';
@import '../tools/tools.color-property-by-theme';


@mixin red-plagiarism-check-theme($theme) {
  .c-plagiarism-check {
    @include color-properties-by-theme($theme, 'color', default);

    &__percent {
      @include color-properties-by-theme($theme, (
        'background-color': default,
        'color':            default-contrast,
      ));
    }

    &__results-item {
      @include color-properties-by-theme($theme, 'color', default);
    }

    a { color: inherit; }
  }
}
