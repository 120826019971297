@use '@angular/material' as mat;
@use 'settings/settings.colors' as colors;

@import '@angular/material/theming';


// Include the common styles for Angular Material. We include this here so that
// you only have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat.core();

// main themes (light and dark)
@import 'red1000';

// component themes
@import 'themes.alert-box';
@import 'themes.buttons';
@import 'themes.card';
@import 'themes.toolbar';
@import 'themes.hamburger-menu';
@import 'themes.accordion';
@import 'themes.dashboard-content';
@import 'themes.icon';
@import 'themes.title-qualifier';
@import 'themes.plagiarism-check';
@import 'themes.pill';


// main theme
@include red-accordion-theme($red1000-light-theme);
@include red-buttons-theme($red1000-light-theme);
@include red-card-theme($red1000-light-theme);
@include red-dashboard-content-theme($red1000-light-theme);
@include red-hamburger-menu-theme($red1000-light-theme);
@include red-icon-theme($red1000-light-theme);
@include red-pill-theme($red1000-light-theme);
@include red-title-qualifier($red1000-light-theme);
@include red-toolbar-theme($red1000-light-theme);


.mat-slider-disabled {
  .mat-slider-thumb, .mat-slider-track-fill {
    background-color: rgba(0, 0, 0, .26) !important;
  }
  .mat-slider-track-background {
    background-color: rgba(0, 0, 0, .1) !important;
  }

  .mat-slider-ticks-container,
  .mat-slider-ticks {
    opacity: 0 !important;
  }
}


// alert levels
@include red-alert-box(colors.$red-alert-levels-theme);
@include red-buttons-theme(colors.$red-alert-levels-theme);
@include red-card-theme(colors.$red-alert-levels-theme);
@include red-icon-theme(colors.$red-alert-levels-theme);
@include red-pill-theme(colors.$red-alert-levels-theme);
@include red-plagiarism-check-theme(colors.$red-alert-levels-theme);
@include red-dashboard-content-theme(colors.$red-alert-levels-theme);

// flags
@include red-buttons-theme(colors.$red-marks-theme);

// proofreading concepts
@include red-icon-theme(colors.$red-score-levels-theme);
@include red-card-theme(colors.$red-score-levels-theme);
