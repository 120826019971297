@import '../settings/index';
@import '../tools/index';


.u-sticky {
  @include mq($from: desktop) {
    position: sticky;
    top:      map-get($toolbar-size, tablet-landscape) + $global-spacing-unit;
  }

  @media print {
    position: static !important;
    top:      0      !important;
  }
}
