@use '@angular/material' as mat;

@import 'settings/index';
@import 'tools/index';


@mixin bgPseudoElement() {
  content:          '';
  position:         absolute;
  top:              0;
  right:            0;
  bottom:           0;
  left:             0;
  pointer-events:   none;
  z-index:          -1;
}

.c-red-table {
  @include font-size($global-epsilon-size);

  position:          relative;
  margin-bottom:     $global-spacing-unit;
  border:            1px solid $red-dark-dividers;
  border-right:      none;
  border-radius:     $global-baseline;
  overflow-x:        auto;
  overflow-y:        hidden;

  &::-webkit-scrollbar {
    width:  $global-spacing-unit-small;
    height: $global-spacing-unit-small;
  }

  &::-webkit-scrollbar-track {
    background-color: $red-light-secondary-bg;
  }

  &::-webkit-scrollbar-thumb {
    background-color: mat.get-color-from-palette($red-accent, default);
  }

  table { border-collapse: separate; }

  thead {
    position: relative;
    z-index:  2;
  }

  tbody {
    position: relative;
    z-index:  1;
  }

  &__title,
  &__cell,
  &__sub {
    position:          relative;
    vertical-align:    bottom;
    padding:           $global-spacing-unit-tiny;
    border-block-end:  1px solid $red-dark-dividers;
    border-inline-end: 1px solid $red-dark-dividers;
    z-index:           0;

    &--number { text-align: end; }
    &--edit   { text-align: end; }
    &--center { text-align: center; }

    &--sticky { position: sticky }

    &--positive {
      $_keys: map-keys($red-info);

      @for $i from 1 through 9 {
        $_key: nth($_keys, $i + 1);

        @if type-of(mat.get-color-from-palette($red-info, $_key)) == color {
          &-#{$i} {
            color:            mat.get-color-from-palette($red-danger, #{ $_key }-contrast, .75) !important;
            background-color: mat.get-color-from-palette($red-info, $_key) !important;
          }
        }
      }
    }

    &--negative {
      $_keys: map-keys($red-danger);

      @for $i from 1 through 9 {
        $_key: nth($_keys, $i + 1);

        @if type-of(mat.get-color-from-palette($red-danger, $_key)) == color {
          &-#{$i} {
            color:            mat.get-color-from-palette($red-danger, #{ $_key }-contrast, .75) !important;
            background-color: mat.get-color-from-palette($red-danger, $_key) !important;
          }
        }
      }
    }
  }

  &__title {
    font-weight:      $bold;
    color:            mat.get-color-from-palette($red-primary, darker);
    background-color: mat.get-color-from-palette($red-primary, darker-contrast);
    z-index:          1;

    &--color {
      @each $key, $value in $red-table-cells {
        &-#{ $key } {
          background-color: mat.get-color-from-palette($value, 100);
        }
      }
    }

    &--highlight {
      background-color: mat.get-color-from-palette($red-primary, 100);
      font-weight:      $bold;
    }
  }

  &__cell {
    background-color: $red-light-primary-bg;

    &--even {
      &::before {
        content:          '';
        position:         absolute;
        top:              0;
        right:            0;
        bottom:           0;
        left:             0;
        background-color: mat.get-color-from-palette($red-primary, default, .075);
        pointer-events:   none;
      }
    }

    &--color {
      @each $key, $value in $red-table-cells {
        &-#{ $key } { background-color: mat.get-color-from-palette($value, 50); }
      }
    }

    &--highlight {
      background-color: mat.get-color-from-palette($red-primary, 50);
      font-weight:      $bold;
    }

    &--hover-highlight {
      &::after {
        @include bgPseudoElement();
        background-color: mat.get-color-from-palette($red-primary, default, .16);
      }
    }
  }

  &__title--sticky { z-index: 2; }

  &__cell--sticky, &__sub--sticky {  z-index: 1; }

  /*&__subgrid {
    @include flush($global-spacing-unit-tiny, true);

    display:           grid;
    grid-auto-flow:    column;
    grid-auto-columns: 1fr;
    height:            calc(100% + #{ $global-spacing-unit-small });
    border:            0;
    border-radius:     0;

    &-cell {
      background-color: transparent;

      &:last-child { border-inline-end: 0; }
    }
  }*/
}
