@use '@angular/material' as mat;

@import 'sass-mq/mq';

@import '../settings/index';


/* ==========================================================================
   #TYPE
   ========================================================================== */

/**
 * Site wide assorted type elements configuration
 *
 * 1. Fonts on OSX will look more consistent with other systems that do not
 *    render text using sub-pixel anti-aliasing.
 */

html {
  font-family: unquote($global-body-font);
  color:       $red-dark-primary-text;

  -moz-osx-font-smoothing: grayscale;    /* [1] */
   -webkit-font-smoothing: antialiased;  /* [1] */
}


a {
  color:           $red-base-link-color;
  text-decoration: none;
  cursor:          pointer;
  transition:      color $global-transition $global-easing;

  &:hover {
    color:           $red-hover-link-color;
    text-decoration: underline;
  }

  &:active {
    color: $red-active-link-color;
  }

  figure & {
    display: block;

    &:hover { color: currentColor; }
  }

  h1 &,
  h2 &,
  h3 &,
  h4 &,
  h5 &,
  h6 & {
    color: $red-dark-primary-text;

    &:hover {
      color: $red-hover-link-color;
    }
  }
}


p {
  margin-bottom: $global-spacing-unit-small;

  &:last-of-type { margin-bottom: 0; }
}

b, strong { font-weight: $bold; }





/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

// $global-alpha to $global-zeta font-size variables
// are defined in 'settings/settings.global'.

h1, h2, h3 { text-transform: uppercase; }

.omega { @include font-size($global-omega-size); }

h1, .alpha {
  @include font-size($global-alpha-size - 8);

  .u-title-qualifier { @include font-size($global-gamma-size); }
}

h2, .beta {
  @include font-size($global-beta-size);

  .u-title-qualifier { @include font-size($global-delta-size); }
}

h3, .gamma {
  @include font-size($global-gamma-size);

  .u-title-qualifier { @include font-size($global-epsilon-size); }
}

h4, .delta {
  @include font-size($global-delta-size);

  .u-title-qualifier { @include font-size($global-zeta-size); }
}

h5, .epsilon {
  @include font-size($global-epsilon-size);
}

h6, .zeta {
  @include font-size($global-zeta-size);
}


@include mq($from: tablet-landscape) {
  h1, .alpha { @include font-size($global-alpha-size); }
}


h1, h2, h3 {
  font-family: unquote($global-headings-font);
  color:       mat.get-color-from-palette($red-primary, default);
  font-weight: $black;

  .u-title-qualifier {
    display:        block;
    font-family:    unquote($global-body-font);
    //text-transform: none;
  }
}

