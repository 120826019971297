@import '@angular/material/theming';

@import '../settings/index';
@import '../tools/tools.color-property-by-theme';


@mixin red-accordion-theme($theme) {

  .c-accordion {

    &-icon {
      background-color: transparent;
    }

    & &__panel,
    &__link {
      border-top:    1px solid $red-dark-dividers;
      border-bottom: 1px solid $red-light-dividers;

      box-shadow:
        inset 0 2px 3px 0 rgba($red-dark-dividers, .05);

      transition:
        box-shadow       $global-transition $global-easing,
        color            $global-transition $global-easing,
        background-color $global-transition $global-easing;
    }

    & &__panel {
      @include color-properties-by-theme($theme, (
        'background-color': default,
        'color':            default-contrast
      ));

      &:hover {
        box-shadow: none;

        @include color-properties-by-theme($theme, 'background-color', lighter, .38);
      }
    }

    &__link {
      @include color-properties-by-theme($theme, (
        'background-color': darker,
        'color':            (darker-contrast, .87)
      ));

      &:hover {
        @include color-properties-by-theme($theme, (
          'color':            darker-contrast,
          'background-color': (darker, .64)
        ));

        box-shadow: none;
      }
    }

    &__item:last-child &__link { border-bottom: none; }

  }

}
