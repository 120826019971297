@import '../settings/index';
@import '../tools/index';


.c-pill {
  @include font-size($global-zeta-size, 1);

  display:          inline-block;
  padding:          ($global-baseline * 0.5) $global-baseline;
  width:            fit-content;
  border:           1px solid;
  border-radius:    $global-baseline;
  text-transform:   uppercase;
  font-weight:      $bold;
  background-color: $red-light-secondary-bg;
  color:            $red-dark-primary-text;

  transition: background-color $global-transition ease-in-out,
              color            $global-transition ease-in-out;

  &--fixed-width {
    text-align:     center;
    vertical-align: middle;
  }
}
